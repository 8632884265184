





















































































































































































import axios from 'axios';
import moment from 'moment';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { VarianceApi } from '@/api/varianceApi';
import { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import { baConfig } from '../../../config';
import UiButton from '../ui/UiButton.vue';
import UiDatePicker from '../ui/UiDatePicker.vue';
import UiLoading from '../ui/UiLoading.vue';
import UiSelect2 from '../ui/UiSelect2.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';

@Component({
  components: {
    UiButton,
    UiSelect2,
    UiDatePicker,
    UiLoading,
    UiTextEdit,
  },
})
export default class VarianceReport extends BaseVue {
  public get headers() {
    return Object.values(this.columns).map((x) => x.heading);
  }

  private readonly standardColumns = {
    date: { heading: 'Date', size: 100 / 5 },
    walletId: { heading: 'Wallet ID', size: 100 / 5 },
    coinId: { heading: 'Coin ID', size: 100 / 5 },
    registerBalance: { heading: 'Register Balance' },
    externalBalance: { heading: 'External Balance', size: 100 / 5 },
    adjustmentRequired: { heading: 'Adjustment Required', size: 100 / 5 },
    adjustmentAmount: { heading: 'Adjustment Amount', size: 100 / 5 },
    adjustmentActionSummary: { heading: 'Adjustment Action Summary', size: 100 / 5 },
  };

  private readonly esColumns = {
    date: { heading: 'Date', size: 100 / 5 },
    walletId: { heading: 'Wallet ID', size: 100 / 5 },
    assetId: { heading: 'Asset ID', size: 100 / 5 },
    assetSymbol: { heading: 'Asset Symbol', size: 100 / 5 },
    externalBalanceTimestamp: { heading: 'External Balance Timestamp', size: 100 / 5 },
    registerBalance: { heading: 'Register Balance' },
    externalBalance: { heading: 'External Balance', size: 100 / 5 },
    balanceDifference: { heading: 'Balance Difference', size: 100 / 5 },
  };

  public get columns() {
    return this.checkFeatureFlag('event-sourced-txns') ? this.esColumns : this.standardColumns;
  }

  // ui state
  public running = false;
  public ticker?: string = '';

  // form state
  private readonly defaultSelectedWallet: Wallet = { name: '' };
  private readonly defaultSelectedDate: string = moment.tz(moment.tz.guess()).subtract(1, 'day').format('YYYY-MM-DD');

  public selectedDate = this.defaultSelectedDate;
  public selectedWallet: Wallet = this.defaultSelectedWallet;

  // report state
  public varianceReport: any = null;

  public reportCSV: string | undefined | null = null;

  public handleClear() {
    this.selectedWallet = this.defaultSelectedWallet;
    this.selectedDate = this.defaultSelectedDate;
  }

  public handleSelectWallet(wallet: Wallet) {
    this.selectedWallet = wallet;
  }

  public async handleRunReport() {
    try {
      this.running = true;
      const orgId = this.$store.state.currentOrg.id;
      const coinId = await this.getCoinId(this.ticker);
      const report = await VarianceApi.createReport(
        orgId,
        new Date(this.selectedDate),
        this.selectedWallet.id ?? undefined,
        coinId
      );
      this.varianceReport = report.metadata;
      this.reportCSV = report.csv;
    } catch (error) {
    } finally {
      this.running = false;
    }
  }

  public async getCoinId(ticker: string | undefined): Promise<string | undefined> {
    if (!ticker) return undefined;
    else {
      const assetsApiUrl = `${baConfig.addressSvcUrl}/symbols/${this.ticker}`;

      const resp = await axios.get(assetsApiUrl);
      if (resp === undefined || resp.status !== 200) {
        this.showErrorSnackbar(`Invalid ticker ${this.ticker}`);
        throw new Error(`Invalid ticker ${this.ticker}`);
      } else {
        return `COIN.${resp.data.coinId}`;
      }
    }
  }

  public handleDownloadReport() {
    if (!this.reportCSV) return;
    const orgId = this.$store.state.currentOrg.id;

    // Create a Blob object from the file data
    const blob = new Blob([this.reportCSV], { type: 'text/csv' });
    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `variance_report_${orgId}_${Date.now()}.csv`;

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);
  }

  public get wallets(): Wallet[] {
    return [{ name: 'ALL WALLETS', id: undefined }, ...this.$store.getters['wallets/WALLETS']];
  }

  @Watch('$store.state.currentOrg.id')
  onCurrentOrgChange() {
    this.handleClear();
    this.varianceReport = null;
    this.reportCSV = null;
  }
}
