/* tslint:disable */
/* eslint-disable */
/**
 * hello-world-svc
 * Bitwave Hello World
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface DataSvcAddressSetPointer
 */
export interface DataSvcAddressSetPointer {
  /**
   *
   * @type {string}
   * @memberof DataSvcAddressSetPointer
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcAddressSetPointer
   */
  name: string;
}
/**
 *
 * @export
 * @interface DataSvcBlockchainDataSource
 */
export interface DataSvcBlockchainDataSource {
  /**
   *
   * @type {string}
   * @memberof DataSvcBlockchainDataSource
   */
  id?: string;
  /**
   *
   * @type {DataSvcDataSourceTypes}
   * @memberof DataSvcBlockchainDataSource
   */
  type: DataSvcDataSourceTypes;
  /**
   *
   * @type {string}
   * @memberof DataSvcBlockchainDataSource
   */
  name: string;
  /**
   *
   * @type {DataSvcDataSourceStatus}
   * @memberof DataSvcBlockchainDataSource
   */
  status: DataSvcDataSourceStatus;
  /**
   *
   * @type {number}
   * @memberof DataSvcBlockchainDataSource
   */
  currentThroughSEC?: number;
  /**
   *
   * @type {DataSvcDataSourceSchema}
   * @memberof DataSvcBlockchainDataSource
   */
  schema: DataSvcDataSourceSchema;
  /**
   *
   * @type {string}
   * @memberof DataSvcBlockchainDataSource
   */
  schemaId: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof DataSvcBlockchainDataSource
   */
  params: object;
  /**
   *
   * @type {DataSvcBlockchainDataSourceNetworks}
   * @memberof DataSvcBlockchainDataSource
   */
  network: DataSvcBlockchainDataSourceNetworks;
  /**
   *
   * @type {DataSvcAddressSetPointer}
   * @memberof DataSvcBlockchainDataSource
   */
  addressSet: DataSvcAddressSetPointer;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcBlockchainDataSourceNetworks = {
  Eth: 'ETH',
  Btc: 'BTC',
} as const;

export type DataSvcBlockchainDataSourceNetworks =
  typeof DataSvcBlockchainDataSourceNetworks[keyof typeof DataSvcBlockchainDataSourceNetworks];

/**
 *
 * @export
 * @interface DataSvcColumnComparison
 */
export interface DataSvcColumnComparison {
  /**
   *
   * @type {string}
   * @memberof DataSvcColumnComparison
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcColumnComparison
   */
  leftValue: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcColumnComparison
   */
  rightValue: string;
  /**
   *
   * @type {DataSvcVariance}
   * @memberof DataSvcColumnComparison
   */
  variance: DataSvcVariance;
}
/**
 *
 * @export
 * @interface DataSvcCommitDataSourceLoad200Response
 */
export interface DataSvcCommitDataSourceLoad200Response {
  /**
   *
   * @type {boolean}
   * @memberof DataSvcCommitDataSourceLoad200Response
   */
  success: DataSvcCommitDataSourceLoad200ResponseSuccessEnum;
  /**
   *
   * @type {string}
   * @memberof DataSvcCommitDataSourceLoad200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcCommitDataSourceLoad200Response
   */
  errorCode: string;
}

export const DataSvcCommitDataSourceLoad200ResponseSuccessEnum = {
  True: true,
} as const;

export type DataSvcCommitDataSourceLoad200ResponseSuccessEnum =
  typeof DataSvcCommitDataSourceLoad200ResponseSuccessEnum[keyof typeof DataSvcCommitDataSourceLoad200ResponseSuccessEnum];

/**
 *
 * @export
 * @interface DataSvcCreateDataSourceLoad200Response
 */
export interface DataSvcCreateDataSourceLoad200Response {
  /**
   *
   * @type {string}
   * @memberof DataSvcCreateDataSourceLoad200Response
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcCreateDataSourceLoad200Response
   */
  uploadUrl: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcCreateDataSourceLoad200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcCreateDataSourceLoad200Response
   */
  errorCode: string;
}
/**
 *
 * @export
 * @interface DataSvcCreateDataSources200Response
 */
export interface DataSvcCreateDataSources200Response {
  /**
   *
   * @type {string}
   * @memberof DataSvcCreateDataSources200Response
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcCreateDataSources200Response
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcCreateDataSources200Response
   */
  errorCode: string;
}
/**
 *
 * @export
 * @interface DataSvcCreateResponse
 */
export interface DataSvcCreateResponse {
  /**
   *
   * @type {string}
   * @memberof DataSvcCreateResponse
   */
  id: string;
}
/**
 *
 * @export
 * @interface DataSvcDataSourceColumnDefinition
 */
export interface DataSvcDataSourceColumnDefinition {
  /**
   *
   * @type {string}
   * @memberof DataSvcDataSourceColumnDefinition
   */
  name: string;
  /**
   *
   * @type {DataSvcDataSourceFieldType}
   * @memberof DataSvcDataSourceColumnDefinition
   */
  type: DataSvcDataSourceFieldType;
  /**
   *
   * @type {Array<DataSvcDataSourceColumnDefinition>}
   * @memberof DataSvcDataSourceColumnDefinition
   */
  fields?: Array<DataSvcDataSourceColumnDefinition>;
  /**
   *
   * @type {string}
   * @memberof DataSvcDataSourceColumnDefinition
   */
  mode?: DataSvcDataSourceColumnDefinitionModeEnum;
  /**
   *
   * @type {string}
   * @memberof DataSvcDataSourceColumnDefinition
   */
  formula?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataSvcDataSourceColumnDefinition
   */
  values?: Array<string>;
}

export const DataSvcDataSourceColumnDefinitionModeEnum = {
  Array: 'array',
  Projection: 'projection',
} as const;

export type DataSvcDataSourceColumnDefinitionModeEnum =
  typeof DataSvcDataSourceColumnDefinitionModeEnum[keyof typeof DataSvcDataSourceColumnDefinitionModeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcDataSourceFieldType = {
  String: 'string',
  Number: 'number',
  Bignumber: 'bignumber',
  Boolean: 'boolean',
  Enum: 'enum',
  Date: 'date',
  Timestamp: 'timestamp',
} as const;

export type DataSvcDataSourceFieldType = typeof DataSvcDataSourceFieldType[keyof typeof DataSvcDataSourceFieldType];

/**
 *
 * @export
 * @interface DataSvcDataSourceRequest
 */
export interface DataSvcDataSourceRequest {
  /**
   *
   * @type {string}
   * @memberof DataSvcDataSourceRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcDataSourceRequest
   */
  schemaId: string;
  /**
   *
   * @type {any}
   * @memberof DataSvcDataSourceRequest
   */
  params?: any;
}
/**
 *
 * @export
 * @interface DataSvcDataSourceSchema
 */
export interface DataSvcDataSourceSchema {
  /**
   *
   * @type {Array<DataSvcDataSourceColumnDefinition>}
   * @memberof DataSvcDataSourceSchema
   */
  fields: Array<DataSvcDataSourceColumnDefinition>;
  /**
   *
   * @type {string}
   * @memberof DataSvcDataSourceSchema
   */
  timestampField?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcDataSourceStatus = {
  New: 'New',
  Loading: 'Loading',
  Active: 'Active',
  Archived: 'Archived',
} as const;

export type DataSvcDataSourceStatus = typeof DataSvcDataSourceStatus[keyof typeof DataSvcDataSourceStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcDataSourceTypes = {
  Custom: 'Custom',
  BigQuery: 'BigQuery',
  Blockchain: 'Blockchain',
  Loaded: 'Loaded',
} as const;

export type DataSvcDataSourceTypes = typeof DataSvcDataSourceTypes[keyof typeof DataSvcDataSourceTypes];

/**
 *
 * @export
 * @interface DataSvcErrorResponse
 */
export interface DataSvcErrorResponse {
  /**
   *
   * @type {string}
   * @memberof DataSvcErrorResponse
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcErrorResponse
   */
  errorCode: string;
}
/**
 *
 * @export
 * @interface DataSvcFeedRequest
 */
export interface DataSvcFeedRequest {
  /**
   *
   * @type {string}
   * @memberof DataSvcFeedRequest
   */
  FeedId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcFeedRequest
   */
  Name: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcFeedRequest
   */
  Frequency: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcFeedRequest
   */
  OrgId: string;
  /**
   *
   * @type {DataSvcWorfklowRequestUser}
   * @memberof DataSvcFeedRequest
   */
  User: DataSvcWorfklowRequestUser;
  /**
   *
   * @type {string}
   * @memberof DataSvcFeedRequest
   */
  DataSourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcFeedRequest
   */
  Status?: string;
}
/**
 *
 * @export
 * @interface DataSvcFieldComparison
 */
export interface DataSvcFieldComparison {
  /**
   *
   * @type {string}
   * @memberof DataSvcFieldComparison
   */
  field: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcFieldComparison
   */
  comparisonType: DataSvcFieldComparisonComparisonTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DataSvcFieldComparison
   */
  allowedDelta?: string;
  /**
   *
   * @type {number}
   * @memberof DataSvcFieldComparison
   */
  allowedDeltaPercent?: number;
}

export const DataSvcFieldComparisonComparisonTypeEnum = {
  Strict: 'strict',
  Loose: 'loose',
} as const;

export type DataSvcFieldComparisonComparisonTypeEnum =
  typeof DataSvcFieldComparisonComparisonTypeEnum[keyof typeof DataSvcFieldComparisonComparisonTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcFlagStatus = {
  None: 'none',
  Flagged: 'flagged',
  Resolved: 'resolved',
} as const;

export type DataSvcFlagStatus = typeof DataSvcFlagStatus[keyof typeof DataSvcFlagStatus];

/**
 *
 * @export
 * @interface DataSvcGetDataSourceById200Response
 */
export interface DataSvcGetDataSourceById200Response {
  /**
   *
   * @type {string}
   * @memberof DataSvcGetDataSourceById200Response
   */
  id?: string;
  /**
   *
   * @type {DataSvcDataSourceTypes}
   * @memberof DataSvcGetDataSourceById200Response
   */
  type: DataSvcDataSourceTypes;
  /**
   *
   * @type {string}
   * @memberof DataSvcGetDataSourceById200Response
   */
  name: string;
  /**
   *
   * @type {DataSvcDataSourceStatus}
   * @memberof DataSvcGetDataSourceById200Response
   */
  status: DataSvcDataSourceStatus;
  /**
   *
   * @type {number}
   * @memberof DataSvcGetDataSourceById200Response
   */
  currentThroughSEC?: number;
  /**
   *
   * @type {DataSvcDataSourceSchema}
   * @memberof DataSvcGetDataSourceById200Response
   */
  schema: DataSvcDataSourceSchema;
  /**
   *
   * @type {string}
   * @memberof DataSvcGetDataSourceById200Response
   */
  schemaId: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof DataSvcGetDataSourceById200Response
   */
  params: object;
  /**
   *
   * @type {DataSvcBlockchainDataSourceNetworks}
   * @memberof DataSvcGetDataSourceById200Response
   */
  network: DataSvcBlockchainDataSourceNetworks;
  /**
   *
   * @type {DataSvcAddressSetPointer}
   * @memberof DataSvcGetDataSourceById200Response
   */
  addressSet: DataSvcAddressSetPointer;
  /**
   *
   * @type {string}
   * @memberof DataSvcGetDataSourceById200Response
   */
  timestampColumn?: string;
}
/**
 *
 * @export
 * @interface DataSvcGetDataSourceRows200Response
 */
export interface DataSvcGetDataSourceRows200Response {
  /**
   *
   * @type {number}
   * @memberof DataSvcGetDataSourceRows200Response
   */
  dataLength: number;
  /**
   *
   * @type {Array<any>}
   * @memberof DataSvcGetDataSourceRows200Response
   */
  items: Array<any>;
}
/**
 *
 * @export
 * @interface DataSvcGetFileURLForExecutionResults200Response
 */
export interface DataSvcGetFileURLForExecutionResults200Response {
  /**
   *
   * @type {string}
   * @memberof DataSvcGetFileURLForExecutionResults200Response
   */
  fileUrl: string;
}
/**
 *
 * @export
 * @interface DataSvcGetWizardReportById200Response
 */
export interface DataSvcGetWizardReportById200Response {
  /**
   *
   * @type {any}
   * @memberof DataSvcGetWizardReportById200Response
   */
  dataLength: any;
  /**
   *
   * @type {Array<any>}
   * @memberof DataSvcGetWizardReportById200Response
   */
  items: Array<any>;
}
/**
 *
 * @export
 * @interface DataSvcGetWizardReports200Response
 */
export interface DataSvcGetWizardReports200Response {
  /**
   *
   * @type {any}
   * @memberof DataSvcGetWizardReports200Response
   */
  dataLength: any;
  /**
   *
   * @type {Array<any>}
   * @memberof DataSvcGetWizardReports200Response
   */
  items: Array<any>;
}
/**
 *
 * @export
 * @interface DataSvcHealthDTO
 */
export interface DataSvcHealthDTO {
  /**
   *
   * @type {string}
   * @memberof DataSvcHealthDTO
   */
  service: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcHealthDTO
   */
  status: DataSvcHealthDTOStatusEnum;
}

export const DataSvcHealthDTOStatusEnum = {
  Ok: 'OK',
  Error: 'ERROR',
} as const;

export type DataSvcHealthDTOStatusEnum = typeof DataSvcHealthDTOStatusEnum[keyof typeof DataSvcHealthDTOStatusEnum];

/**
 *
 * @export
 * @interface DataSvcICustomSchema
 */
export interface DataSvcICustomSchema {
  /**
   *
   * @type {string}
   * @memberof DataSvcICustomSchema
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcICustomSchema
   */
  orgID: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcICustomSchema
   */
  name: string;
  /**
   *
   * @type {DataSvcDataSourceSchema}
   * @memberof DataSvcICustomSchema
   */
  definition: DataSvcDataSourceSchema;
  /**
   *
   * @type {DataSvcISchemaStatus}
   * @memberof DataSvcICustomSchema
   */
  status: DataSvcISchemaStatus;
  /**
   *
   * @type {string}
   * @memberof DataSvcICustomSchema
   */
  params: string | null;
  /**
   *
   * @type {number}
   * @memberof DataSvcICustomSchema
   */
  skipLeadingRows: number;
  /**
   *
   * @type {string}
   * @memberof DataSvcICustomSchema
   */
  schemaType: string;
}
/**
 *
 * @export
 * @interface DataSvcISchema
 */
export interface DataSvcISchema {
  /**
   *
   * @type {string}
   * @memberof DataSvcISchema
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcISchema
   */
  name: string;
  /**
   *
   * @type {DataSvcDataSourceSchema}
   * @memberof DataSvcISchema
   */
  definition: DataSvcDataSourceSchema;
  /**
   *
   * @type {DataSvcISchemaStatus}
   * @memberof DataSvcISchema
   */
  status: DataSvcISchemaStatus;
  /**
   *
   * @type {string}
   * @memberof DataSvcISchema
   */
  params: string | null;
  /**
   *
   * @type {number}
   * @memberof DataSvcISchema
   */
  skipLeadingRows: number;
  /**
   *
   * @type {string}
   * @memberof DataSvcISchema
   */
  schemaType: string;
  /**
   *
   * @type {boolean}
   * @memberof DataSvcISchema
   */
  isSchemaBeta: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcISchemaStatus = {
  Enabled: 'Enabled',
  Disabled: 'Disabled',
} as const;

export type DataSvcISchemaStatus = typeof DataSvcISchemaStatus[keyof typeof DataSvcISchemaStatus];

/**
 *
 * @export
 * @interface DataSvcJobPointerInterface
 */
export interface DataSvcJobPointerInterface {
  /**
   *
   * @type {DataSvcJobPointerInterfaceLinks}
   * @memberof DataSvcJobPointerInterface
   */
  _links: DataSvcJobPointerInterfaceLinks;
  /**
   *
   * @type {string}
   * @memberof DataSvcJobPointerInterface
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcJobPointerInterface
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcJobPointerInterface
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcJobPointerInterface
   */
  groupName: string;
  /**
   *
   * @type {number}
   * @memberof DataSvcJobPointerInterface
   */
  cadenceSEC: number;
}
/**
 *
 * @export
 * @interface DataSvcJobPointerInterfaceLinks
 */
export interface DataSvcJobPointerInterfaceLinks {
  /**
   *
   * @type {DataSvcJobPointerInterfaceLinksExec}
   * @memberof DataSvcJobPointerInterfaceLinks
   */
  exec: DataSvcJobPointerInterfaceLinksExec;
}
/**
 *
 * @export
 * @interface DataSvcJobPointerInterfaceLinksExec
 */
export interface DataSvcJobPointerInterfaceLinksExec {
  /**
   *
   * @type {string}
   * @memberof DataSvcJobPointerInterfaceLinksExec
   */
  method: DataSvcJobPointerInterfaceLinksExecMethodEnum;
  /**
   *
   * @type {string}
   * @memberof DataSvcJobPointerInterfaceLinksExec
   */
  href: string;
}

export const DataSvcJobPointerInterfaceLinksExecMethodEnum = {
  Post: 'POST',
} as const;

export type DataSvcJobPointerInterfaceLinksExecMethodEnum =
  typeof DataSvcJobPointerInterfaceLinksExecMethodEnum[keyof typeof DataSvcJobPointerInterfaceLinksExecMethodEnum];

/**
 *
 * @export
 * @interface DataSvcJobRun
 */
export interface DataSvcJobRun {
  /**
   *
   * @type {string}
   * @memberof DataSvcJobRun
   */
  runId: string;
  /**
   *
   * @type {boolean}
   * @memberof DataSvcJobRun
   */
  startedSuccessfully: boolean;
}
/**
 *
 * @export
 * @interface DataSvcJobsPageResultDto
 */
export interface DataSvcJobsPageResultDto {
  /**
   *
   * @type {string}
   * @memberof DataSvcJobsPageResultDto
   */
  nextPageToken?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcJobsPageResultDto
   */
  service?: string;
  /**
   *
   * @type {Array<DataSvcJobPointerInterface>}
   * @memberof DataSvcJobsPageResultDto
   */
  jobs?: Array<DataSvcJobPointerInterface>;
}
/**
 *
 * @export
 * @interface DataSvcLoadImportExecutionResults200Response
 */
export interface DataSvcLoadImportExecutionResults200Response {
  /**
   *
   * @type {number}
   * @memberof DataSvcLoadImportExecutionResults200Response
   */
  creationFailedLength?: number;
  /**
   *
   * @type {number}
   * @memberof DataSvcLoadImportExecutionResults200Response
   */
  validationFailedLength?: number;
  /**
   *
   * @type {any}
   * @memberof DataSvcLoadImportExecutionResults200Response
   */
  results: any;
  /**
   *
   * @type {any}
   * @memberof DataSvcLoadImportExecutionResults200Response
   */
  execution: any;
}
/**
 *
 * @export
 * @interface DataSvcLoadUrlCreateResponse
 */
export interface DataSvcLoadUrlCreateResponse {
  /**
   *
   * @type {string}
   * @memberof DataSvcLoadUrlCreateResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcLoadUrlCreateResponse
   */
  uploadUrl: string;
}
/**
 *
 * @export
 * @interface DataSvcLoadedDataSource
 */
export interface DataSvcLoadedDataSource {
  /**
   *
   * @type {string}
   * @memberof DataSvcLoadedDataSource
   */
  id?: string;
  /**
   *
   * @type {DataSvcDataSourceTypes}
   * @memberof DataSvcLoadedDataSource
   */
  type: DataSvcDataSourceTypes;
  /**
   *
   * @type {string}
   * @memberof DataSvcLoadedDataSource
   */
  name: string;
  /**
   *
   * @type {DataSvcDataSourceStatus}
   * @memberof DataSvcLoadedDataSource
   */
  status: DataSvcDataSourceStatus;
  /**
   *
   * @type {number}
   * @memberof DataSvcLoadedDataSource
   */
  currentThroughSEC?: number;
  /**
   *
   * @type {DataSvcDataSourceSchema}
   * @memberof DataSvcLoadedDataSource
   */
  schema: DataSvcDataSourceSchema;
  /**
   *
   * @type {string}
   * @memberof DataSvcLoadedDataSource
   */
  schemaId: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof DataSvcLoadedDataSource
   */
  params: object;
  /**
   *
   * @type {string}
   * @memberof DataSvcLoadedDataSource
   */
  timestampColumn?: string;
}
/**
 *
 * @export
 * @interface DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSource
 */
export interface DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSource {
  /**
   *
   * @type {Array<DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner>}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSource
   */
  items: Array<DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner>;
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSource
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
 */
export interface DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner {
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  id?: string;
  /**
   *
   * @type {DataSvcDataSourceTypes}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  type: DataSvcDataSourceTypes;
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  name: string;
  /**
   *
   * @type {DataSvcDataSourceStatus}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  status: DataSvcDataSourceStatus;
  /**
   *
   * @type {number}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  currentThroughSEC?: number;
  /**
   *
   * @type {DataSvcDataSourceSchema}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  schema: DataSvcDataSourceSchema;
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  schemaId: string;
  /**
   * Construct a type with a set of properties K of type T
   * @type {object}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  params: object;
  /**
   *
   * @type {DataSvcBlockchainDataSourceNetworks}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  network: DataSvcBlockchainDataSourceNetworks;
  /**
   *
   * @type {DataSvcAddressSetPointer}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  addressSet: DataSvcAddressSetPointer;
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSourceItemsInner
   */
  timestampColumn?: string;
}
/**
 *
 * @export
 * @interface DataSvcPaginatedResponseReconciliationDefinition
 */
export interface DataSvcPaginatedResponseReconciliationDefinition {
  /**
   *
   * @type {Array<DataSvcReconciliationDefinition>}
   * @memberof DataSvcPaginatedResponseReconciliationDefinition
   */
  items: Array<DataSvcReconciliationDefinition>;
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseReconciliationDefinition
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface DataSvcPaginatedResponseReconciliationRow
 */
export interface DataSvcPaginatedResponseReconciliationRow {
  /**
   *
   * @type {Array<DataSvcReconciliationRow>}
   * @memberof DataSvcPaginatedResponseReconciliationRow
   */
  items: Array<DataSvcReconciliationRow>;
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseReconciliationRow
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface DataSvcPaginatedResponseReconciliationRun
 */
export interface DataSvcPaginatedResponseReconciliationRun {
  /**
   *
   * @type {Array<DataSvcReconciliationRun>}
   * @memberof DataSvcPaginatedResponseReconciliationRun
   */
  items: Array<DataSvcReconciliationRun>;
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseReconciliationRun
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface DataSvcPaginatedResponseSchema
 */
export interface DataSvcPaginatedResponseSchema {
  /**
   *
   * @type {Array<DataSvcSchema>}
   * @memberof DataSvcPaginatedResponseSchema
   */
  items: Array<DataSvcSchema>;
  /**
   *
   * @type {string}
   * @memberof DataSvcPaginatedResponseSchema
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface DataSvcPipeRequest
 */
export interface DataSvcPipeRequest {
  /**
   *
   * @type {string}
   * @memberof DataSvcPipeRequest
   */
  dataSourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcPipeRequest
   */
  sink: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcPipeRequest
   */
  wizardReportId?: string;
  /**
   *
   * @type {DataSvcPipeStep}
   * @memberof DataSvcPipeRequest
   */
  step?: DataSvcPipeStep;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcPipeStep = {
  Validation: 'Validation',
  Creation: 'Creation',
} as const;

export type DataSvcPipeStep = typeof DataSvcPipeStep[keyof typeof DataSvcPipeStep];

/**
 *
 * @export
 * @interface DataSvcReconciliationDefinition
 */
export interface DataSvcReconciliationDefinition {
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationDefinition
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationDefinition
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationDefinition
   */
  leftDataSourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationDefinition
   */
  leftFilter?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationDefinition
   */
  rightDataSourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationDefinition
   */
  rightFilter?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationDefinition
   */
  matchField: string;
  /**
   *
   * @type {Array<DataSvcFieldComparison>}
   * @memberof DataSvcReconciliationDefinition
   */
  comparisonFields: Array<DataSvcFieldComparison>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcReconciliationMethod = {
  UnReconciled: 'un-reconciled',
  AutoReconciled: 'auto-reconciled',
  ManuallyReconciled: 'manually-reconciled',
} as const;

export type DataSvcReconciliationMethod = typeof DataSvcReconciliationMethod[keyof typeof DataSvcReconciliationMethod];

/**
 *
 * @export
 * @interface DataSvcReconciliationRow
 */
export interface DataSvcReconciliationRow {
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationRow
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationRow
   */
  version?: string;
  /**
   *
   * @type {DataSvcVariance}
   * @memberof DataSvcReconciliationRow
   */
  variance: DataSvcVariance;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationRow
   */
  message?: string;
  /**
   *
   * @type {object}
   * @memberof DataSvcReconciliationRow
   */
  leftData?: object;
  /**
   *
   * @type {object}
   * @memberof DataSvcReconciliationRow
   */
  rightData?: object;
  /**
   *
   * @type {boolean}
   * @memberof DataSvcReconciliationRow
   */
  reconciled: boolean;
  /**
   *
   * @type {DataSvcReconciliationMethod}
   * @memberof DataSvcReconciliationRow
   */
  reconciliationMethod?: DataSvcReconciliationMethod;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationRow
   */
  reconciliationComments?: string;
  /**
   *
   * @type {DataSvcFlagStatus}
   * @memberof DataSvcReconciliationRow
   */
  flag?: DataSvcFlagStatus;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationRow
   */
  assignedToUserId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataSvcReconciliationRow
   */
  hasDiscussion?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof DataSvcReconciliationRow
   */
  labels?: Array<string>;
  /**
   *
   * @type {Array<DataSvcColumnComparison>}
   * @memberof DataSvcReconciliationRow
   */
  comparisons: Array<DataSvcColumnComparison>;
}
/**
 *
 * @export
 * @interface DataSvcReconciliationRun
 */
export interface DataSvcReconciliationRun {
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationRun
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof DataSvcReconciliationRun
   */
  createdSEC: number;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationRun
   */
  reconciliationDefinitionId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcReconciliationRun
   */
  jobId: string;
  /**
   *
   * @type {number}
   * @memberof DataSvcReconciliationRun
   */
  leftCount?: number;
  /**
   *
   * @type {number}
   * @memberof DataSvcReconciliationRun
   */
  rightCount?: number;
  /**
   *
   * @type {DataSvcReconciliationRunStatus}
   * @memberof DataSvcReconciliationRun
   */
  status: DataSvcReconciliationRunStatus;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcReconciliationRunStatus = {
  New: 'New',
  Loading: 'Loading',
  InProgress: 'InProgress',
  Complete: 'Complete',
  Errored: 'Errored',
} as const;

export type DataSvcReconciliationRunStatus =
  typeof DataSvcReconciliationRunStatus[keyof typeof DataSvcReconciliationRunStatus];

/**
 *
 * @export
 * @interface DataSvcSchema
 */
export interface DataSvcSchema {
  /**
   *
   * @type {string}
   * @memberof DataSvcSchema
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcSchema
   */
  name: string;
  /**
   *
   * @type {DataSvcISchemaStatus}
   * @memberof DataSvcSchema
   */
  status: DataSvcISchemaStatus;
  /**
   *
   * @type {DataSvcDataSourceSchema}
   * @memberof DataSvcSchema
   */
  definition: DataSvcDataSourceSchema;
  /**
   *
   * @type {string}
   * @memberof DataSvcSchema
   */
  params: string | null;
  /**
   *
   * @type {string}
   * @memberof DataSvcSchema
   */
  schemaType: string;
  /**
   *
   * @type {boolean}
   * @memberof DataSvcSchema
   */
  isSchemaBeta: boolean;
}
/**
 *
 * @export
 * @interface DataSvcStart200Response
 */
export interface DataSvcStart200Response {
  /**
   *
   * @type {string}
   * @memberof DataSvcStart200Response
   */
  executionId: string;
  /**
   *
   * @type {any}
   * @memberof DataSvcStart200Response
   */
  message: any;
}
/**
 *
 * @export
 * @interface DataSvcStart200ResponseAnyOf
 */
export interface DataSvcStart200ResponseAnyOf {
  /**
   *
   * @type {any}
   * @memberof DataSvcStart200ResponseAnyOf
   */
  executionId?: any;
  /**
   *
   * @type {string}
   * @memberof DataSvcStart200ResponseAnyOf
   */
  message: string;
}
/**
 *
 * @export
 * @interface DataSvcStart200ResponseAnyOf1
 */
export interface DataSvcStart200ResponseAnyOf1 {
  /**
   *
   * @type {any}
   * @memberof DataSvcStart200ResponseAnyOf1
   */
  message?: any;
  /**
   *
   * @type {string}
   * @memberof DataSvcStart200ResponseAnyOf1
   */
  executionId: string;
}
/**
 *
 * @export
 * @interface DataSvcSuccessResponse
 */
export interface DataSvcSuccessResponse {
  /**
   *
   * @type {boolean}
   * @memberof DataSvcSuccessResponse
   */
  success: DataSvcSuccessResponseSuccessEnum;
}

export const DataSvcSuccessResponseSuccessEnum = {
  True: true,
} as const;

export type DataSvcSuccessResponseSuccessEnum =
  typeof DataSvcSuccessResponseSuccessEnum[keyof typeof DataSvcSuccessResponseSuccessEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const DataSvcVariance = {
  None: 'none',
  MissingLeft: 'missing-left',
  MissingRight: 'missing-right',
  NearMatch: 'near-match',
  Mismatch: 'mismatch',
} as const;

export type DataSvcVariance = typeof DataSvcVariance[keyof typeof DataSvcVariance];

/**
 *
 * @export
 * @interface DataSvcWizardRequest
 */
export interface DataSvcWizardRequest {
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  WizardReportId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  ValidationWorkflowExecutionId?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  CreationWorkflowExecutionId?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  TotalTransactions?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  ValidatedTransaction?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  CreatedTransaction?: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  OrgId: string;
  /**
   *
   * @type {DataSvcWorfklowRequestUser}
   * @memberof DataSvcWizardRequest
   */
  User: DataSvcWorfklowRequestUser;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  DataSourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  SchemaId: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWizardRequest
   */
  Status?: string;
}
/**
 *
 * @export
 * @interface DataSvcWorfklowRequest
 */
export interface DataSvcWorfklowRequest {
  /**
   *
   * @type {DataSvcWorfklowRequestUser}
   * @memberof DataSvcWorfklowRequest
   */
  User: DataSvcWorfklowRequestUser;
}
/**
 *
 * @export
 * @interface DataSvcWorfklowRequestUser
 */
export interface DataSvcWorfklowRequestUser {
  /**
   *
   * @type {string}
   * @memberof DataSvcWorfklowRequestUser
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataSvcWorfklowRequestUser
   */
  name: string;
}

/**
 * DataApi - axios parameter creator
 * @export
 */
export const DataApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} loadId
     * @param {boolean} [append]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitDataSourceLoad: async (
      orgId: string,
      dataSourceId: string,
      loadId: string,
      append?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('commitDataSourceLoad', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('commitDataSourceLoad', 'dataSourceId', dataSourceId);
      // verify required parameter 'loadId' is not null or undefined
      assertParamExists('commitDataSourceLoad', 'loadId', loadId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/loads/{loadId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)))
        .replace(`{${'loadId'}}`, encodeURIComponent(String(loadId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (append !== undefined) {
        localVarQueryParameter['append'] = append;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataSourceLoad: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createDataSourceLoad', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('createDataSourceLoad', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/loads`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcDataSourceRequest} dataSvcDataSourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataSources: async (
      orgId: string,
      dataSvcDataSourceRequest: DataSvcDataSourceRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('createDataSources', 'orgId', orgId);
      // verify required parameter 'dataSvcDataSourceRequest' is not null or undefined
      assertParamExists('createDataSources', 'dataSvcDataSourceRequest', dataSvcDataSourceRequest);
      const localVarPath = `/orgs/{orgId}/data-sources`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataSvcDataSourceRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcICustomSchema} dataSvcICustomSchema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customSchema: async (
      orgId: string,
      dataSvcICustomSchema: DataSvcICustomSchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('customSchema', 'orgId', orgId);
      // verify required parameter 'dataSvcICustomSchema' is not null or undefined
      assertParamExists('customSchema', 'dataSvcICustomSchema', dataSvcICustomSchema);
      const localVarPath = `/orgs/{orgId}/custom-schema`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataSvcICustomSchema, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcFeedRequest} dataSvcFeedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feed: async (
      orgId: string,
      dataSvcFeedRequest: DataSvcFeedRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('feed', 'orgId', orgId);
      // verify required parameter 'dataSvcFeedRequest' is not null or undefined
      assertParamExists('feed', 'dataSvcFeedRequest', dataSvcFeedRequest);
      const localVarPath = `/orgs/{orgId}/feed`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataSvcFeedRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataSourceById: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getDataSourceById', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('getDataSourceById', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-source/{dataSourceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [schemaId]
     * @param {string} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataSourceRows: async (
      orgId: string,
      dataSourceId: string,
      schemaId?: string,
      pageToken?: string,
      pageLimit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getDataSourceRows', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('getDataSourceRows', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/rows`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (schemaId !== undefined) {
        localVarQueryParameter['schemaId'] = schemaId;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} schemaId
     * @param {string} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataSourceSummary: async (
      orgId: string,
      dataSourceId: string,
      schemaId: string,
      pageToken?: string,
      pageLimit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getDataSourceSummary', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('getDataSourceSummary', 'dataSourceId', dataSourceId);
      // verify required parameter 'schemaId' is not null or undefined
      assertParamExists('getDataSourceSummary', 'schemaId', schemaId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/schema/{schemaId}/summary`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)))
        .replace(`{${'schemaId'}}`, encodeURIComponent(String(schemaId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExecutions: async (
      orgId: string,
      workflowDefinitionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getExecutions', 'orgId', orgId);
      // verify required parameter 'workflowDefinitionId' is not null or undefined
      assertParamExists('getExecutions', 'workflowDefinitionId', workflowDefinitionId);
      const localVarPath = `/orgs/{orgId}/workflows/{workflowDefinitionId}/executions`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'workflowDefinitionId'}}`, encodeURIComponent(String(workflowDefinitionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} feedExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeedById: async (
      orgId: string,
      feedExecutionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getFeedById', 'orgId', orgId);
      // verify required parameter 'feedExecutionId' is not null or undefined
      assertParamExists('getFeedById', 'feedExecutionId', feedExecutionId);
      const localVarPath = `/orgs/{orgId}/feed/{feedExecutionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedExecutionId'}}`, encodeURIComponent(String(feedExecutionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeedExecutions: async (
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getFeedExecutions', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/feedExecutions`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeeds: async (
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getFeeds', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/feeds`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceName
     * @param {string} workflowDefinitionId
     * @param {string} workflowExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileURLForExecutionResults: async (
      orgId: string,
      dataSourceName: string,
      workflowDefinitionId: string,
      workflowExecutionId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getFileURLForExecutionResults', 'orgId', orgId);
      // verify required parameter 'dataSourceName' is not null or undefined
      assertParamExists('getFileURLForExecutionResults', 'dataSourceName', dataSourceName);
      // verify required parameter 'workflowDefinitionId' is not null or undefined
      assertParamExists('getFileURLForExecutionResults', 'workflowDefinitionId', workflowDefinitionId);
      // verify required parameter 'workflowExecutionId' is not null or undefined
      assertParamExists('getFileURLForExecutionResults', 'workflowExecutionId', workflowExecutionId);
      const localVarPath =
        `/orgs/{orgId}/workflows/{workflowDefinitionId}/execution/{workflowExecutionId}/datasource/{dataSourceName}/download`
          .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
          .replace(`{${'dataSourceName'}}`, encodeURIComponent(String(dataSourceName)))
          .replace(`{${'workflowDefinitionId'}}`, encodeURIComponent(String(workflowDefinitionId)))
          .replace(`{${'workflowExecutionId'}}`, encodeURIComponent(String(workflowExecutionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {string} runId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReconciliationRows: async (
      orgId: string,
      reconciliationId: string,
      runId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getReconciliationRows', 'orgId', orgId);
      // verify required parameter 'reconciliationId' is not null or undefined
      assertParamExists('getReconciliationRows', 'reconciliationId', reconciliationId);
      // verify required parameter 'runId' is not null or undefined
      assertParamExists('getReconciliationRows', 'runId', runId);
      const localVarPath = `/orgs/{orgId}/reconciliations/{reconciliationId}/runs/{runId}/rows`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'reconciliationId'}}`, encodeURIComponent(String(reconciliationId)))
        .replace(`{${'runId'}}`, encodeURIComponent(String(runId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} wizardReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWizardReportById: async (
      orgId: string,
      wizardReportId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getWizardReportById', 'orgId', orgId);
      // verify required parameter 'wizardReportId' is not null or undefined
      assertParamExists('getWizardReportById', 'wizardReportId', wizardReportId);
      const localVarPath = `/orgs/{orgId}/wizard/{wizardReportId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'wizardReportId'}}`, encodeURIComponent(String(wizardReportId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWizardReports: async (
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getWizardReports', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/wizards`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomSchemas: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('listCustomSchemas', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/custom-schemas`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDataSources: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('listDataSources', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/data-sources`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listGenericSchemas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/hawaii/schemas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listReconciliations: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('listReconciliations', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/reconciliations`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRuns: async (
      orgId: string,
      reconciliationId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('listRuns', 'orgId', orgId);
      // verify required parameter 'reconciliationId' is not null or undefined
      assertParamExists('listRuns', 'reconciliationId', reconciliationId);
      const localVarPath = `/orgs/{orgId}/reconciliations/{reconciliationId}/runs`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'reconciliationId'}}`, encodeURIComponent(String(reconciliationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSchemas: async (orgId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('listSchemas', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/schemas`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} workflowExecutionId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadImportExecutionResults: async (
      orgId: string,
      workflowDefinitionId: string,
      workflowExecutionId: string,
      pageToken?: number,
      pageLimit?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('loadImportExecutionResults', 'orgId', orgId);
      // verify required parameter 'workflowDefinitionId' is not null or undefined
      assertParamExists('loadImportExecutionResults', 'workflowDefinitionId', workflowDefinitionId);
      // verify required parameter 'workflowExecutionId' is not null or undefined
      assertParamExists('loadImportExecutionResults', 'workflowExecutionId', workflowExecutionId);
      const localVarPath = `/orgs/{orgId}/workflows/{workflowDefinitionId}/execution/{workflowExecutionId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'workflowDefinitionId'}}`, encodeURIComponent(String(workflowDefinitionId)))
        .replace(`{${'workflowExecutionId'}}`, encodeURIComponent(String(workflowExecutionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageLimit !== undefined) {
        localVarQueryParameter['pageLimit'] = pageLimit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcPipeRequest} dataSvcPipeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pipe: async (
      orgId: string,
      dataSvcPipeRequest: DataSvcPipeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('pipe', 'orgId', orgId);
      // verify required parameter 'dataSvcPipeRequest' is not null or undefined
      assertParamExists('pipe', 'dataSvcPipeRequest', dataSvcPipeRequest);
      const localVarPath = `/orgs/{orgId}/pipes`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataSvcPipeRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    run: async (orgId: string, reconciliationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('run', 'orgId', orgId);
      // verify required parameter 'reconciliationId' is not null or undefined
      assertParamExists('run', 'reconciliationId', reconciliationId);
      const localVarPath = `/orgs/{orgId}/reconciliations/{reconciliationId}/runs`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'reconciliationId'}}`, encodeURIComponent(String(reconciliationId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcISchema} dataSvcISchema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schema: async (
      orgId: string,
      dataSvcISchema: DataSvcISchema,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('schema', 'orgId', orgId);
      // verify required parameter 'dataSvcISchema' is not null or undefined
      assertParamExists('schema', 'dataSvcISchema', dataSvcISchema);
      const localVarPath = `/orgs/{orgId}/schema`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataSvcISchema, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Starts a workflow instance running
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} feedId
     * @param {DataSvcWorfklowRequest} dataSvcWorfklowRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startFeed: async (
      orgId: string,
      workflowDefinitionId: string,
      feedId: string,
      dataSvcWorfklowRequest: DataSvcWorfklowRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('startFeed', 'orgId', orgId);
      // verify required parameter 'workflowDefinitionId' is not null or undefined
      assertParamExists('startFeed', 'workflowDefinitionId', workflowDefinitionId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('startFeed', 'feedId', feedId);
      // verify required parameter 'dataSvcWorfklowRequest' is not null or undefined
      assertParamExists('startFeed', 'dataSvcWorfklowRequest', dataSvcWorfklowRequest);
      const localVarPath = `/orgs/{orgId}/feed/{workflowDefinitionId}/{feedId}/start`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'workflowDefinitionId'}}`, encodeURIComponent(String(workflowDefinitionId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataSvcWorfklowRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {DataSvcDataSourceRequest} dataSvcDataSourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataSource: async (
      orgId: string,
      dataSourceId: string,
      dataSvcDataSourceRequest: DataSvcDataSourceRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('updateDataSource', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('updateDataSource', 'dataSourceId', dataSourceId);
      // verify required parameter 'dataSvcDataSourceRequest' is not null or undefined
      assertParamExists('updateDataSource', 'dataSvcDataSourceRequest', dataSvcDataSourceRequest);
      const localVarPath = `/orgs/{orgId}/data-source/{dataSourceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataSvcDataSourceRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcWizardRequest} dataSvcWizardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wizard: async (
      orgId: string,
      dataSvcWizardRequest: DataSvcWizardRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('wizard', 'orgId', orgId);
      // verify required parameter 'dataSvcWizardRequest' is not null or undefined
      assertParamExists('wizard', 'dataSvcWizardRequest', dataSvcWizardRequest);
      const localVarPath = `/orgs/{orgId}/wizard`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataSvcWizardRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataApi - functional programming interface
 * @export
 */
export const DataApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DataApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} loadId
     * @param {boolean} [append]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commitDataSourceLoad(
      orgId: string,
      dataSourceId: string,
      loadId: string,
      append?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcCommitDataSourceLoad200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.commitDataSourceLoad(
        orgId,
        dataSourceId,
        loadId,
        append,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDataSourceLoad(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcCreateDataSourceLoad200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDataSourceLoad(orgId, dataSourceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcDataSourceRequest} dataSvcDataSourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDataSources(
      orgId: string,
      dataSvcDataSourceRequest: DataSvcDataSourceRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcCreateDataSources200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDataSources(
        orgId,
        dataSvcDataSourceRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcICustomSchema} dataSvcICustomSchema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async customSchema(
      orgId: string,
      dataSvcICustomSchema: DataSvcICustomSchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.customSchema(orgId, dataSvcICustomSchema, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcFeedRequest} dataSvcFeedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feed(
      orgId: string,
      dataSvcFeedRequest: DataSvcFeedRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feed(orgId, dataSvcFeedRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataSourceById(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetDataSourceById200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSourceById(orgId, dataSourceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [schemaId]
     * @param {string} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataSourceRows(
      orgId: string,
      dataSourceId: string,
      schemaId?: string,
      pageToken?: string,
      pageLimit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetDataSourceRows200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSourceRows(
        orgId,
        dataSourceId,
        schemaId,
        pageToken,
        pageLimit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} schemaId
     * @param {string} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataSourceSummary(
      orgId: string,
      dataSourceId: string,
      schemaId: string,
      pageToken?: string,
      pageLimit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetDataSourceRows200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataSourceSummary(
        orgId,
        dataSourceId,
        schemaId,
        pageToken,
        pageLimit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getExecutions(
      orgId: string,
      workflowDefinitionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<any>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getExecutions(orgId, workflowDefinitionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} feedExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFeedById(
      orgId: string,
      feedExecutionId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetWizardReportById200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedById(orgId, feedExecutionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFeedExecutions(
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetWizardReports200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedExecutions(orgId, pageToken, pageLimit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFeeds(
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetWizardReports200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFeeds(orgId, pageToken, pageLimit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceName
     * @param {string} workflowDefinitionId
     * @param {string} workflowExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFileURLForExecutionResults(
      orgId: string,
      dataSourceName: string,
      workflowDefinitionId: string,
      workflowExecutionId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetFileURLForExecutionResults200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFileURLForExecutionResults(
        orgId,
        dataSourceName,
        workflowDefinitionId,
        workflowExecutionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {string} runId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReconciliationRows(
      orgId: string,
      reconciliationId: string,
      runId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcPaginatedResponseReconciliationRow>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReconciliationRows(
        orgId,
        reconciliationId,
        runId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} wizardReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWizardReportById(
      orgId: string,
      wizardReportId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetWizardReportById200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWizardReportById(orgId, wizardReportId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWizardReports(
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcGetWizardReports200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWizardReports(orgId, pageToken, pageLimit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listCustomSchemas(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcPaginatedResponseSchema>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listCustomSchemas(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listDataSources(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSource>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listDataSources(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listGenericSchemas(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcPaginatedResponseSchema>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listGenericSchemas(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listReconciliations(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcPaginatedResponseReconciliationDefinition>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listReconciliations(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listRuns(
      orgId: string,
      reconciliationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcPaginatedResponseReconciliationRun>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listRuns(orgId, reconciliationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listSchemas(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcPaginatedResponseSchema>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listSchemas(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} workflowExecutionId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async loadImportExecutionResults(
      orgId: string,
      workflowDefinitionId: string,
      workflowExecutionId: string,
      pageToken?: number,
      pageLimit?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcLoadImportExecutionResults200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.loadImportExecutionResults(
        orgId,
        workflowDefinitionId,
        workflowExecutionId,
        pageToken,
        pageLimit,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcPipeRequest} dataSvcPipeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pipe(
      orgId: string,
      dataSvcPipeRequest: DataSvcPipeRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pipe(orgId, dataSvcPipeRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async run(
      orgId: string,
      reconciliationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcJobRun>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.run(orgId, reconciliationId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcISchema} dataSvcISchema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schema(
      orgId: string,
      dataSvcISchema: DataSvcISchema,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schema(orgId, dataSvcISchema, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Starts a workflow instance running
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} feedId
     * @param {DataSvcWorfklowRequest} dataSvcWorfklowRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startFeed(
      orgId: string,
      workflowDefinitionId: string,
      feedId: string,
      dataSvcWorfklowRequest: DataSvcWorfklowRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcStart200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startFeed(
        orgId,
        workflowDefinitionId,
        feedId,
        dataSvcWorfklowRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {DataSvcDataSourceRequest} dataSvcDataSourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDataSource(
      orgId: string,
      dataSourceId: string,
      dataSvcDataSourceRequest: DataSvcDataSourceRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataSource(
        orgId,
        dataSourceId,
        dataSvcDataSourceRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcWizardRequest} dataSvcWizardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wizard(
      orgId: string,
      dataSvcWizardRequest: DataSvcWizardRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wizard(orgId, dataSvcWizardRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DataApi - factory interface
 * @export
 */
export const DataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DataApiFp(configuration);
  return {
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} loadId
     * @param {boolean} [append]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commitDataSourceLoad(
      orgId: string,
      dataSourceId: string,
      loadId: string,
      append?: boolean,
      options?: any
    ): AxiosPromise<DataSvcCommitDataSourceLoad200Response> {
      return localVarFp
        .commitDataSourceLoad(orgId, dataSourceId, loadId, append, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataSourceLoad(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataSvcCreateDataSourceLoad200Response> {
      return localVarFp.createDataSourceLoad(orgId, dataSourceId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcDataSourceRequest} dataSvcDataSourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDataSources(
      orgId: string,
      dataSvcDataSourceRequest: DataSvcDataSourceRequest,
      options?: any
    ): AxiosPromise<DataSvcCreateDataSources200Response> {
      return localVarFp
        .createDataSources(orgId, dataSvcDataSourceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcICustomSchema} dataSvcICustomSchema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    customSchema(orgId: string, dataSvcICustomSchema: DataSvcICustomSchema, options?: any): AxiosPromise<boolean> {
      return localVarFp.customSchema(orgId, dataSvcICustomSchema, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcFeedRequest} dataSvcFeedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feed(orgId: string, dataSvcFeedRequest: DataSvcFeedRequest, options?: any): AxiosPromise<boolean> {
      return localVarFp.feed(orgId, dataSvcFeedRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataSourceById(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataSvcGetDataSourceById200Response> {
      return localVarFp.getDataSourceById(orgId, dataSourceId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [schemaId]
     * @param {string} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataSourceRows(
      orgId: string,
      dataSourceId: string,
      schemaId?: string,
      pageToken?: string,
      pageLimit?: number,
      options?: any
    ): AxiosPromise<DataSvcGetDataSourceRows200Response> {
      return localVarFp
        .getDataSourceRows(orgId, dataSourceId, schemaId, pageToken, pageLimit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} schemaId
     * @param {string} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataSourceSummary(
      orgId: string,
      dataSourceId: string,
      schemaId: string,
      pageToken?: string,
      pageLimit?: number,
      options?: any
    ): AxiosPromise<DataSvcGetDataSourceRows200Response> {
      return localVarFp
        .getDataSourceSummary(orgId, dataSourceId, schemaId, pageToken, pageLimit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getExecutions(orgId: string, workflowDefinitionId: string, options?: any): AxiosPromise<Array<any>> {
      return localVarFp.getExecutions(orgId, workflowDefinitionId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} feedExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeedById(
      orgId: string,
      feedExecutionId: string,
      options?: any
    ): AxiosPromise<DataSvcGetWizardReportById200Response> {
      return localVarFp.getFeedById(orgId, feedExecutionId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeedExecutions(
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options?: any
    ): AxiosPromise<DataSvcGetWizardReports200Response> {
      return localVarFp
        .getFeedExecutions(orgId, pageToken, pageLimit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFeeds(
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options?: any
    ): AxiosPromise<DataSvcGetWizardReports200Response> {
      return localVarFp.getFeeds(orgId, pageToken, pageLimit, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceName
     * @param {string} workflowDefinitionId
     * @param {string} workflowExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFileURLForExecutionResults(
      orgId: string,
      dataSourceName: string,
      workflowDefinitionId: string,
      workflowExecutionId: string,
      options?: any
    ): AxiosPromise<DataSvcGetFileURLForExecutionResults200Response> {
      return localVarFp
        .getFileURLForExecutionResults(orgId, dataSourceName, workflowDefinitionId, workflowExecutionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {string} runId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReconciliationRows(
      orgId: string,
      reconciliationId: string,
      runId: string,
      options?: any
    ): AxiosPromise<DataSvcPaginatedResponseReconciliationRow> {
      return localVarFp
        .getReconciliationRows(orgId, reconciliationId, runId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} wizardReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWizardReportById(
      orgId: string,
      wizardReportId: string,
      options?: any
    ): AxiosPromise<DataSvcGetWizardReportById200Response> {
      return localVarFp.getWizardReportById(orgId, wizardReportId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWizardReports(
      orgId: string,
      pageToken?: number,
      pageLimit?: number,
      options?: any
    ): AxiosPromise<DataSvcGetWizardReports200Response> {
      return localVarFp
        .getWizardReports(orgId, pageToken, pageLimit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listCustomSchemas(orgId: string, options?: any): AxiosPromise<DataSvcPaginatedResponseSchema> {
      return localVarFp.listCustomSchemas(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listDataSources(
      orgId: string,
      options?: any
    ): AxiosPromise<DataSvcPaginatedResponseBlockchainDataSourceOrLoadedDataSource> {
      return localVarFp.listDataSources(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listGenericSchemas(options?: any): AxiosPromise<DataSvcPaginatedResponseSchema> {
      return localVarFp.listGenericSchemas(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listReconciliations(orgId: string, options?: any): AxiosPromise<DataSvcPaginatedResponseReconciliationDefinition> {
      return localVarFp.listReconciliations(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRuns(
      orgId: string,
      reconciliationId: string,
      options?: any
    ): AxiosPromise<DataSvcPaginatedResponseReconciliationRun> {
      return localVarFp.listRuns(orgId, reconciliationId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listSchemas(orgId: string, options?: any): AxiosPromise<DataSvcPaginatedResponseSchema> {
      return localVarFp.listSchemas(orgId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} workflowExecutionId
     * @param {number} [pageToken]
     * @param {number} [pageLimit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loadImportExecutionResults(
      orgId: string,
      workflowDefinitionId: string,
      workflowExecutionId: string,
      pageToken?: number,
      pageLimit?: number,
      options?: any
    ): AxiosPromise<DataSvcLoadImportExecutionResults200Response> {
      return localVarFp
        .loadImportExecutionResults(orgId, workflowDefinitionId, workflowExecutionId, pageToken, pageLimit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcPipeRequest} dataSvcPipeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pipe(orgId: string, dataSvcPipeRequest: DataSvcPipeRequest, options?: any): AxiosPromise<any> {
      return localVarFp.pipe(orgId, dataSvcPipeRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} reconciliationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    run(orgId: string, reconciliationId: string, options?: any): AxiosPromise<DataSvcJobRun> {
      return localVarFp.run(orgId, reconciliationId, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcISchema} dataSvcISchema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schema(orgId: string, dataSvcISchema: DataSvcISchema, options?: any): AxiosPromise<boolean> {
      return localVarFp.schema(orgId, dataSvcISchema, options).then((request) => request(axios, basePath));
    },
    /**
     * Starts a workflow instance running
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} feedId
     * @param {DataSvcWorfklowRequest} dataSvcWorfklowRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startFeed(
      orgId: string,
      workflowDefinitionId: string,
      feedId: string,
      dataSvcWorfklowRequest: DataSvcWorfklowRequest,
      options?: any
    ): AxiosPromise<DataSvcStart200Response> {
      return localVarFp
        .startFeed(orgId, workflowDefinitionId, feedId, dataSvcWorfklowRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {DataSvcDataSourceRequest} dataSvcDataSourceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataSource(
      orgId: string,
      dataSourceId: string,
      dataSvcDataSourceRequest: DataSvcDataSourceRequest,
      options?: any
    ): AxiosPromise<boolean> {
      return localVarFp
        .updateDataSource(orgId, dataSourceId, dataSvcDataSourceRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} orgId
     * @param {DataSvcWizardRequest} dataSvcWizardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wizard(orgId: string, dataSvcWizardRequest: DataSvcWizardRequest, options?: any): AxiosPromise<boolean> {
      return localVarFp.wizard(orgId, dataSvcWizardRequest, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DataApi - object-oriented interface
 * @export
 * @class DataApi
 * @extends {BaseAPI}
 */
export class DataApi extends BaseAPI {
  /**
   *
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} loadId
   * @param {boolean} [append]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public commitDataSourceLoad(
    orgId: string,
    dataSourceId: string,
    loadId: string,
    append?: boolean,
    options?: AxiosRequestConfig
  ) {
    return DataApiFp(this.configuration)
      .commitDataSourceLoad(orgId, dataSourceId, loadId, append, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public createDataSourceLoad(orgId: string, dataSourceId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .createDataSourceLoad(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {DataSvcDataSourceRequest} dataSvcDataSourceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public createDataSources(
    orgId: string,
    dataSvcDataSourceRequest: DataSvcDataSourceRequest,
    options?: AxiosRequestConfig
  ) {
    return DataApiFp(this.configuration)
      .createDataSources(orgId, dataSvcDataSourceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {DataSvcICustomSchema} dataSvcICustomSchema
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public customSchema(orgId: string, dataSvcICustomSchema: DataSvcICustomSchema, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .customSchema(orgId, dataSvcICustomSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {DataSvcFeedRequest} dataSvcFeedRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public feed(orgId: string, dataSvcFeedRequest: DataSvcFeedRequest, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .feed(orgId, dataSvcFeedRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getDataSourceById(orgId: string, dataSourceId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .getDataSourceById(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [schemaId]
   * @param {string} [pageToken]
   * @param {number} [pageLimit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getDataSourceRows(
    orgId: string,
    dataSourceId: string,
    schemaId?: string,
    pageToken?: string,
    pageLimit?: number,
    options?: AxiosRequestConfig
  ) {
    return DataApiFp(this.configuration)
      .getDataSourceRows(orgId, dataSourceId, schemaId, pageToken, pageLimit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} schemaId
   * @param {string} [pageToken]
   * @param {number} [pageLimit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getDataSourceSummary(
    orgId: string,
    dataSourceId: string,
    schemaId: string,
    pageToken?: string,
    pageLimit?: number,
    options?: AxiosRequestConfig
  ) {
    return DataApiFp(this.configuration)
      .getDataSourceSummary(orgId, dataSourceId, schemaId, pageToken, pageLimit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} workflowDefinitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getExecutions(orgId: string, workflowDefinitionId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .getExecutions(orgId, workflowDefinitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} feedExecutionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getFeedById(orgId: string, feedExecutionId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .getFeedById(orgId, feedExecutionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {number} [pageToken]
   * @param {number} [pageLimit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getFeedExecutions(orgId: string, pageToken?: number, pageLimit?: number, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .getFeedExecutions(orgId, pageToken, pageLimit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {number} [pageToken]
   * @param {number} [pageLimit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getFeeds(orgId: string, pageToken?: number, pageLimit?: number, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .getFeeds(orgId, pageToken, pageLimit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} dataSourceName
   * @param {string} workflowDefinitionId
   * @param {string} workflowExecutionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getFileURLForExecutionResults(
    orgId: string,
    dataSourceName: string,
    workflowDefinitionId: string,
    workflowExecutionId: string,
    options?: AxiosRequestConfig
  ) {
    return DataApiFp(this.configuration)
      .getFileURLForExecutionResults(orgId, dataSourceName, workflowDefinitionId, workflowExecutionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} reconciliationId
   * @param {string} runId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getReconciliationRows(orgId: string, reconciliationId: string, runId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .getReconciliationRows(orgId, reconciliationId, runId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} wizardReportId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getWizardReportById(orgId: string, wizardReportId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .getWizardReportById(orgId, wizardReportId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {number} [pageToken]
   * @param {number} [pageLimit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public getWizardReports(orgId: string, pageToken?: number, pageLimit?: number, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .getWizardReports(orgId, pageToken, pageLimit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public listCustomSchemas(orgId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .listCustomSchemas(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public listDataSources(orgId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .listDataSources(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public listGenericSchemas(options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .listGenericSchemas(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public listReconciliations(orgId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .listReconciliations(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} reconciliationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public listRuns(orgId: string, reconciliationId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .listRuns(orgId, reconciliationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public listSchemas(orgId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .listSchemas(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} workflowDefinitionId
   * @param {string} workflowExecutionId
   * @param {number} [pageToken]
   * @param {number} [pageLimit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public loadImportExecutionResults(
    orgId: string,
    workflowDefinitionId: string,
    workflowExecutionId: string,
    pageToken?: number,
    pageLimit?: number,
    options?: AxiosRequestConfig
  ) {
    return DataApiFp(this.configuration)
      .loadImportExecutionResults(orgId, workflowDefinitionId, workflowExecutionId, pageToken, pageLimit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {DataSvcPipeRequest} dataSvcPipeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public pipe(orgId: string, dataSvcPipeRequest: DataSvcPipeRequest, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .pipe(orgId, dataSvcPipeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} reconciliationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public run(orgId: string, reconciliationId: string, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .run(orgId, reconciliationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {DataSvcISchema} dataSvcISchema
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public schema(orgId: string, dataSvcISchema: DataSvcISchema, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .schema(orgId, dataSvcISchema, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts a workflow instance running
   * @param {string} orgId
   * @param {string} workflowDefinitionId
   * @param {string} feedId
   * @param {DataSvcWorfklowRequest} dataSvcWorfklowRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public startFeed(
    orgId: string,
    workflowDefinitionId: string,
    feedId: string,
    dataSvcWorfklowRequest: DataSvcWorfklowRequest,
    options?: AxiosRequestConfig
  ) {
    return DataApiFp(this.configuration)
      .startFeed(orgId, workflowDefinitionId, feedId, dataSvcWorfklowRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {DataSvcDataSourceRequest} dataSvcDataSourceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public updateDataSource(
    orgId: string,
    dataSourceId: string,
    dataSvcDataSourceRequest: DataSvcDataSourceRequest,
    options?: AxiosRequestConfig
  ) {
    return DataApiFp(this.configuration)
      .updateDataSource(orgId, dataSourceId, dataSvcDataSourceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} orgId
   * @param {DataSvcWizardRequest} dataSvcWizardRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataApi
   */
  public wizard(orgId: string, dataSvcWizardRequest: DataSvcWizardRequest, options?: AxiosRequestConfig) {
    return DataApiFp(this.configuration)
      .wizard(orgId, dataSvcWizardRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fail: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/fail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fail(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fail(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ping(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcHealthDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fail(options?: any): AxiosPromise<void> {
      return localVarFp.fail(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ping(options?: any): AxiosPromise<DataSvcHealthDTO> {
      return localVarFp.ping(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public fail(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .fail(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public ping(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .ping(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Callback for the workflow step
     * @param {string} workflowDefinitionId
     * @param {string} workflow
     * @param {string} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callback: async (
      workflowDefinitionId: string,
      workflow: string,
      step: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'workflowDefinitionId' is not null or undefined
      assertParamExists('callback', 'workflowDefinitionId', workflowDefinitionId);
      // verify required parameter 'workflow' is not null or undefined
      assertParamExists('callback', 'workflow', workflow);
      // verify required parameter 'step' is not null or undefined
      assertParamExists('callback', 'step', step);
      const localVarPath = `/jobs/callback/workflowDefinitions/{workflowDefinitionId}/workflows/{workflow}/steps/{step}`
        .replace(`{${'workflowDefinitionId'}}`, encodeURIComponent(String(workflowDefinitionId)))
        .replace(`{${'workflow'}}`, encodeURIComponent(String(workflow)))
        .replace(`{${'step'}}`, encodeURIComponent(String(step)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a list of jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/jobs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Starts a workflow instance running
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} feedId
     * @param {string} [taskFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    start: async (
      orgId: string,
      workflowDefinitionId: string,
      feedId: string,
      taskFilter?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('start', 'orgId', orgId);
      // verify required parameter 'workflowDefinitionId' is not null or undefined
      assertParamExists('start', 'workflowDefinitionId', workflowDefinitionId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('start', 'feedId', feedId);
      const localVarPath = `/jobs/{workflowDefinitionId}/{orgId}/{feedId}/start`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'workflowDefinitionId'}}`, encodeURIComponent(String(workflowDefinitionId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (taskFilter !== undefined) {
        localVarQueryParameter['taskFilter'] = taskFilter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration);
  return {
    /**
     * Callback for the workflow step
     * @param {string} workflowDefinitionId
     * @param {string} workflow
     * @param {string} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async callback(
      workflowDefinitionId: string,
      workflow: string,
      step: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.callback(workflowDefinitionId, workflow, step, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Gets a list of jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcJobsPageResultDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Starts a workflow instance running
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} feedId
     * @param {string} [taskFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async start(
      orgId: string,
      workflowDefinitionId: string,
      feedId: string,
      taskFilter?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataSvcStart200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.start(
        orgId,
        workflowDefinitionId,
        feedId,
        taskFilter,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = JobsApiFp(configuration);
  return {
    /**
     * Callback for the workflow step
     * @param {string} workflowDefinitionId
     * @param {string} workflow
     * @param {string} step
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    callback(workflowDefinitionId: string, workflow: string, step: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .callback(workflowDefinitionId, workflow, step, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a list of jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): AxiosPromise<DataSvcJobsPageResultDto> {
      return localVarFp.get(options).then((request) => request(axios, basePath));
    },
    /**
     * Starts a workflow instance running
     * @param {string} orgId
     * @param {string} workflowDefinitionId
     * @param {string} feedId
     * @param {string} [taskFilter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    start(
      orgId: string,
      workflowDefinitionId: string,
      feedId: string,
      taskFilter?: string,
      options?: any
    ): AxiosPromise<DataSvcStart200Response> {
      return localVarFp
        .start(orgId, workflowDefinitionId, feedId, taskFilter, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   * Callback for the workflow step
   * @param {string} workflowDefinitionId
   * @param {string} workflow
   * @param {string} step
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public callback(workflowDefinitionId: string, workflow: string, step: string, options?: AxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .callback(workflowDefinitionId, workflow, step, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a list of jobs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public get(options?: AxiosRequestConfig) {
    return JobsApiFp(this.configuration)
      .get(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts a workflow instance running
   * @param {string} orgId
   * @param {string} workflowDefinitionId
   * @param {string} feedId
   * @param {string} [taskFilter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public start(
    orgId: string,
    workflowDefinitionId: string,
    feedId: string,
    taskFilter?: string,
    options?: AxiosRequestConfig
  ) {
    return JobsApiFp(this.configuration)
      .start(orgId, workflowDefinitionId, feedId, taskFilter, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
