import { baConfig } from '../../config';
import { DataApi } from '../../generated/data-svc';

class DataApiManager {
  private static instance: DataApi;

  public static getInstance(): DataApi {
    if (!DataApiManager.instance) {
      DataApiManager.instance = new DataApi(undefined, baConfig.getFriendlyApiUrl() + '/v2');
    }
    return DataApiManager.instance;
  }
}

export default DataApiManager;
