import { baConfig } from '../../config';
import { DefaultApi as DataCoreSvcApi } from '../../generated/data-core-svc';

class DataCoreApiManager {
  private static instance: DataCoreSvcApi;

  public static getInstance(): DataCoreSvcApi {
    if (!DataCoreApiManager.instance) {
      DataCoreApiManager.instance = new DataCoreSvcApi(undefined, baConfig.getFriendlyApiUrl()); // + '/v3'); //TODO: v3 is handled by data-core-svc, should it be here instead?
    }
    return DataCoreApiManager.instance;
  }
}

export default DataCoreApiManager;
