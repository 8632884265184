import gql from 'graphql-tag';

export const RegisterQuery = gql`
  query GetRegister($orgId: ID!, $filter: RegisterFilter!) {
    register(orgId: $orgId, filter: $filter) {
      items {
        transactionId
        createdSEC
        memo
        hasDiscussion
        ticker
        paymentAmount
        depositAmount
        balance
      }
    }
  }
`;
