




















































import axios from 'axios';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import {
  CategorizationStatus,
  Coins,
  Contact,
  ReconciliationStatus,
  Transaction,
  TransactionsResult,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { upload } from '@/services/fileUploadService';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
  },
})
export default class Load extends BaseVue {
  public isLoadingDataSources = false;
  public isUploading = false;

  public uploadProgress = 0;

  public loadedDataSources: any = [];
  public uploadStatus = '';

  public dataSource: { id: string; name: string } | null = null;

  public uploadData: any = null;

  async mounted() {
    await this.loadDataSources();
  }

  async refresh() {
    await this.loadDataSources();
  }

  async doUpload() {
    this.isUploading = true;
    this.uploadStatus = 'Uploading';
    if (!this.dataSource) {
      return null;
    }
    try {
      const ds = DataApiManager.getInstance();
      const loadDetails = await ds.createDataSourceLoad(this.orgId, this.dataSource?.id, { withCredentials: true });
      if (loadDetails.status === 200) {
        // do Upload
        const config = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 95);
            if (this.uploadProgress === 95) {
              this.uploadStatus = 'Wrapping up.';
            }
          },
        };
        await upload(this.uploadData, loadDetails.data.uploadUrl, config);
        const res = await ds.commitDataSourceLoad(this.orgId, this.dataSource?.id, loadDetails.data.id, false, {
          withCredentials: true,
        });
        this.uploadProgress = 100;
        if (res.status === 200) {
          this.showSuccessSnackbar(this.$tc('_dataLoadSuccess'));
        } else {
          this.showErrorSnackbar(this.$tc('_dataLoadFailure'));
          throw new Error(`Commit Data Source load failed ${res.data.message}`);
        }
      } else {
        this.showErrorSnackbar(this.$tc('_dataLoadFailure'));
        throw new Error(`Create Data Source load failed ${loadDetails.data.message}`);
      }
    } catch (err) {
      this.showErrorSnackbar(this.$tc('_dataLoadFailure'));
      console.error(`Error creating data source load - ${err}`);
    } finally {
      this.isUploading = false;
      this.uploadProgress = 0;
      this.uploadStatus = '';
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      fileInput.value = '';
    }
  }

  filesChange(fieldName: string, fileList: any[]) {
    if (fileList.length === 1) {
      this.uploadData = fileList[0];
    }
  }

  async loadDataSources() {
    this.isLoadingDataSources = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        const loaded = resp.data.items.filter((m) => m.type === 'Loaded');
        this.loadedDataSources = [];
        for (const l of loaded) {
          this.loadedDataSources.push({
            name: l.name,
            id: l.id,
          });
        }
      } else {
        const e = new Error('Failed to load data sources. Status Code:' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSources = false;
    }
  }
}
