import axios from 'axios';

import { baConfig } from '../../config';

export class VarianceApi {
  /**
   *
   * @param orgId
   * @param date
   * @param walletId
   * @param coinId
   */
  public static async createReport(orgId: string, date: Date, walletId?: string, coinId?: string) {
    const apiUrl = `${baConfig.apiUrl}orgs/${orgId}/variance`;
    const body = { orgId, date, walletId, coinId };
    const reportResponse = await axios({ url: apiUrl, method: 'POST', data: body, withCredentials: true });
    return reportResponse.data;
  }
}
