








































































































































































import axios from 'axios';
import Component from 'vue-class-component';

import { Contact, ReconciliationStatus, Transaction, Wallet } from '@/api-svc-types';
import TooltipSelect from '@/components/tooltip/TooltipSelect.vue';
import { RegisterQuery } from '@/queries/registerQuery';
import { isDefined } from '@/utils/guards';

import { baConfig } from '../../config';
import { BaseVue } from '../BaseVue';
import CreateManualTransaction from '../components/transactions/CreateManualTransaction.vue';
import SaveInline from '../components/transactions/SaveInline.vue';
import UiButton from '../components/ui/UiButton.vue';
import UiDatePicker from '../components/ui/UiDatePicker.vue';
import UiDropdown from '../components/ui/UiDropdown.vue';
import UiLoading from '../components/ui/UiLoading.vue';
import UiRadioGroup from '../components/ui/UiRadioGroup.vue';
import UiSelect from '../components/ui/UiSelect.vue';
import UiSelect2 from '../components/ui/UiSelect2.vue';
import UiTabs2 from '../components/ui/UiTabs2.vue';
import UiTextEdit from '../components/ui/UiTextEdit.vue';
import UiToggle from '../components/ui/UiToggle.vue';
import UiTooltip from '../components/ui/UiTooltip.vue';
import VarianceReport from '../components/variance/VarianceReport.vue';
import { WalletsQuery } from '../queries/transactionsPageQuery';

enum RegisterTab {
  Register = 'register',
  Variance = 'variance',
}

@Component({
  components: {
    SaveInline,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    CreateManualTransaction,
    TooltipSelect,
    VarianceReport,
    UiTabs2,
    UiSelect2,
    UiToggle,
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoading',
    },
  },
})
export default class Register extends BaseVue {
  public wallets: Wallet[] = [];
  public register?: any = null;
  public maxDate = new Date().toISOString().substring(0, 10);
  public registerTab: RegisterTab = RegisterTab.Register;

  public get headers() {
    return Object.values(this.columns).map((x) => x.heading);
  }

  public activeTabClass = ' tw-border-t-2 tw-border-l-2 tw-border-r-2 tw-border-grey tw-rounded-t-md';

  public columns = {
    date: { heading: 'Date', size: 100 / 5 },
    txn: { heading: 'Txn', size: 100 / 5 },
    ticker: { heading: 'Ticker', size: 100 / 5 },
    amounts: { heading: 'Memo', size: 100 / 5 },
    payment: { heading: 'Payment', size: 100 / 5 },
    deposit: { heading: 'Deposit', size: 100 / 5 },
    balance: { heading: 'Balance', size: 100 / 5 },
  };

  public allSelected = false;
  public isLoading = 0;
  public showFilters = false;
  public skipQuery = true;
  public useFiat = false; // false = coin, true = fiat
  public endDate = new Date().toISOString().substring(0, 10);

  public vars = {
    filter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletId: 'All',
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public get displayRegister() {
    return this.isLoading ? [] : (this.register?.items ?? []).filter(isDefined);
  }

  public async loadRegister() {
    if (this.$store.state.currentOrg && this.walletId !== 'select') {
      this.isLoading = 1;
      let currencyId;
      let hasError = false;

      if (this.ticker && this.ticker !== null) {
        if (this.useFiat) {
          const fiats: Array<{ id: string; name: string; symbol: string }> = this.$store.state.fiats?.fiats ?? [];
          if (typeof fiats !== 'undefined' && Array.isArray(fiats)) {
            const fiat = fiats.find((fiat) => fiat.name === String(this.ticker).toUpperCase());
            if (fiat && fiat.id) {
              currencyId = `FIAT.${fiat.id}`;
            }
          }
        }

        if (!currencyId) {
          const assetsApiUrl = `${baConfig.addressSvcUrl}/symbols/${this.ticker}`;
          const resp = await axios.get(assetsApiUrl);
          if (resp === undefined || resp.status !== 200) {
            hasError = true;
            this.showErrorSnackbar(`Invalid ticker ${this.ticker}`);
          } else {
            currencyId = `COIN.${resp.data.coinId}`;
          }
        }
      }
      if (!hasError) {
        const res = await this.$apollo.query({
          query: RegisterQuery,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            filter: {
              walletId: this.walletId,
              endDate: this.endDate,
              currencyId,
            },
          },
        });
        this.register = res.data.register;
      }

      this.isLoading = 0;
    }
  }

  public trans(translationKey: string): string {
    return this.$t(translationKey) as string;
  }

  public showMenuOn: Transaction | null = null;
  public expandedTxn: Transaction | null = null;
  public showCreateManualTxnModal = false;
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: Transaction | null = null;
  public deleteDialog = false;
  public isSoftLoading = 0;
  public walletId = 'select';
  public ticker: string | null = null;

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public selectTab(tab: string) {
    this.registerTab = tab === 'register' ? RegisterTab.Register : RegisterTab.Variance;
  }

  public async refresh() {
    await Promise.all([this.$apollo.queries.register.refetch()]);

    this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
    this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
  }

  toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }

  public handleDownloadReport() {
    const csvLines: string[] = ['Date,Txn,Ticker,Memo,Payment,Deposit,Balance'];
    if (!this.register) throw new Error('Unable to download register report: No register loaded');

    const transactions = this.register?.items;

    transactions.forEach((transaction: any) => {
      const values = [
        '"' + new Date(transaction.createdSEC * 1000) + '"',
        transaction.transactionId,
        transaction.ticker,
        transaction.memo,
        transaction.paymentAmount,
        transaction.depositAmount,
        transaction.balance,
      ];
      const csvLine = values.join(',');
      csvLines.push(csvLine);
    });

    const csvString = csvLines.join('\n');

    // Create a Blob from the CSV data
    const blob = new Blob([csvString], { type: 'text/csv' });

    // Create a temporary URL for downloading
    const url = window.URL.createObjectURL(blob);

    // Create a hidden anchor element and trigger a click to download the file
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'report.csv';
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the object URL
    window.URL.revokeObjectURL(url);
  }

  public onCurrencyToggle(value: boolean) {
    this.useFiat = value;
  }
}
