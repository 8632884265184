/* tslint:disable */
/* eslint-disable */
/**
 * Data Service API
 * This service provides an API to manage organization data sources.
 *
 * The version of the OpenAPI document: v1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlCreateInput
 */
export interface DataCoreSvcDatasourcehdlCreateInput {
  /**
   *
   * @type {DataCoreSvcDatasourcesCreateDataSourceUnion}
   * @memberof DataCoreSvcDatasourcehdlCreateInput
   */
  data?: DataCoreSvcDatasourcesCreateDataSourceUnion;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlDownloadOutput
 */
export interface DataCoreSvcDatasourcehdlDownloadOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlDownloadOutput
   */
  link?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlListOutput
 */
export interface DataCoreSvcDatasourcehdlListOutput {
  /**
   *
   * @type {Array<DataCoreSvcDatasourcesDataSourcePointer>}
   * @memberof DataCoreSvcDatasourcehdlListOutput
   */
  items?: Array<DataCoreSvcDatasourcesDataSourcePointer> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlListOutput
   */
  pageToken?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlRowsInput
 */
export interface DataCoreSvcDatasourcehdlRowsInput {
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  fields?: Array<string> | null;
  /**
   *
   * @type {Array<DataCoreSvcTransformsColumnFilterUnion>}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  filterBy?: Array<DataCoreSvcTransformsColumnFilterUnion> | null;
  /**
   *
   * @type {Array<DataCoreSvcTransformsColumnOrderBy>}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  orderBy?: Array<DataCoreSvcTransformsColumnOrderBy> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlRowsOutput
 */
export interface DataCoreSvcDatasourcehdlRowsOutput {
  /**
   *
   * @type {Array<any>}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  items?: Array<any> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  pageToken?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlSchemaOutput
 */
export interface DataCoreSvcDatasourcehdlSchemaOutput {
  /**
   *
   * @type {Array<DataCoreSvcDomainField>}
   * @memberof DataCoreSvcDatasourcehdlSchemaOutput
   */
  items?: Array<DataCoreSvcDomainField> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesBigQueryTableDataSource
 */
export interface DataCoreSvcDatasourcesBigQueryTableDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  bqDatasetId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  bqProjectId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  bqRegion?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  bqTableName?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  orgId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  schemaId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  type?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesCreateBigQueryDataSource
 */
export interface DataCoreSvcDatasourcesCreateBigQueryDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateBigQueryDataSource
   */
  datasetId: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateBigQueryDataSource
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateBigQueryDataSource
   */
  projectId: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateBigQueryDataSource
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateBigQueryDataSource
   */
  table: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateBigQueryDataSource
   */
  type: string;
}
/**
 * @type DataCoreSvcDatasourcesCreateDataSourceUnion
 * @export
 */
export type DataCoreSvcDatasourcesCreateDataSourceUnion =
  | DataCoreSvcDatasourcesCreateBigQueryDataSource
  | DataCoreSvcDatasourcesCreateGoogleSheetDataSource;

/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesCreateGoogleSheetDataSource
 */
export interface DataCoreSvcDatasourcesCreateGoogleSheetDataSource {
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDatasourcesCreateGoogleSheetDataSource
   */
  jaggedRows: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateGoogleSheetDataSource
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateGoogleSheetDataSource
   */
  sheetRange: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateGoogleSheetDataSource
   */
  sheetUri: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesCreateGoogleSheetDataSource
   */
  skipLeadingRows: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesCreateGoogleSheetDataSource
   */
  type: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesDataSourcePointer
 */
export interface DataCoreSvcDatasourcesDataSourcePointer {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesDataSourcePointer
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesDataSourcePointer
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesDataSourcePointer
   */
  type?: string;
}
/**
 * @type DataCoreSvcDatasourcesDataSourceUnion
 * @export
 */
export type DataCoreSvcDatasourcesDataSourceUnion =
  | DataCoreSvcDatasourcesBigQueryTableDataSource
  | DataCoreSvcDatasourcesGoogleSheetDataSource;

/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesDataSourceValidationResult
 */
export interface DataCoreSvcDatasourcesDataSourceValidationResult {
  /**
   *
   * @type {Array<DataCoreSvcDomainField>}
   * @memberof DataCoreSvcDatasourcesDataSourceValidationResult
   */
  columns?: Array<DataCoreSvcDomainField> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDatasourcesDataSourceValidationResult
   */
  errors?: Array<string> | null;
  /**
   *
   * @type {Array<DataCoreSvcDomainSchemaPointer>}
   * @memberof DataCoreSvcDatasourcesDataSourceValidationResult
   */
  matchingGlobalSchemas?: Array<DataCoreSvcDomainSchemaPointer> | null;
  /**
   *
   * @type {Array<any>}
   * @memberof DataCoreSvcDatasourcesDataSourceValidationResult
   */
  rowPreview?: Array<any> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesGoogleSheetDataSource
 */
export interface DataCoreSvcDatasourcesGoogleSheetDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  bqDatasetId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  bqProjectId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  bqRegion?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  bqTableName?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  jaggedRows?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  orgId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  schemaId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  sheetRange?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  sheetUri?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  skipLeadingRows?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  type?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDomainField
 */
export interface DataCoreSvcDomainField {
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDomainField
   */
  allowFilter?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDomainField
   */
  allowNull?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDomainField
   */
  allowSort?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDomainField
   */
  defaultTimestampField?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainField
   */
  description?: string;
  /**
   *
   * @type {Array<DataCoreSvcDomainField>}
   * @memberof DataCoreSvcDomainField
   */
  fields?: Array<DataCoreSvcDomainField>;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainField
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainField
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainField
   */
  validation?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDomainField
   */
  values?: Array<string>;
}
/**
 *
 * @export
 * @interface DataCoreSvcDomainSchemaPointer
 */
export interface DataCoreSvcDomainSchemaPointer {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainSchemaPointer
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainSchemaPointer
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainSchemaPointer
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDomainSchemaPointer
   */
  version?: number;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlCreateInput
 */
export interface DataCoreSvcFeedhdlCreateInput {
  /**
   *
   * @type {DataCoreSvcFeedsCreateFeedUnion}
   * @memberof DataCoreSvcFeedhdlCreateInput
   */
  data?: DataCoreSvcFeedsCreateFeedUnion;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlListOutput
 */
export interface DataCoreSvcFeedhdlListOutput {
  /**
   *
   * @type {Array<DataCoreSvcFeedsFeedPointer>}
   * @memberof DataCoreSvcFeedhdlListOutput
   */
  items?: Array<DataCoreSvcFeedsFeedPointer> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlListOutput
   */
  pageToken?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedsCreateAbbsTransactionFeed
 */
export interface DataCoreSvcFeedsCreateAbbsTransactionFeed {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateAbbsTransactionFeed
   */
  datasourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateAbbsTransactionFeed
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateAbbsTransactionFeed
   */
  type: DataCoreSvcFeedsCreateAbbsTransactionFeedTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcFeedsCreateAbbsTransactionFeed
   */
  walletAddresses: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateAbbsTransactionFeed
   */
  walletId: string;
}

export const DataCoreSvcFeedsCreateAbbsTransactionFeedTypeEnum = {
  AbbsTransaction: 'abbs-transaction',
} as const;

export type DataCoreSvcFeedsCreateAbbsTransactionFeedTypeEnum =
  typeof DataCoreSvcFeedsCreateAbbsTransactionFeedTypeEnum[keyof typeof DataCoreSvcFeedsCreateAbbsTransactionFeedTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFeedsCreateAddressedAbbsTransactionFeed
 */
export interface DataCoreSvcFeedsCreateAddressedAbbsTransactionFeed {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateAddressedAbbsTransactionFeed
   */
  datasourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateAddressedAbbsTransactionFeed
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateAddressedAbbsTransactionFeed
   */
  type: DataCoreSvcFeedsCreateAddressedAbbsTransactionFeedTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateAddressedAbbsTransactionFeed
   */
  walletId: string;
}

export const DataCoreSvcFeedsCreateAddressedAbbsTransactionFeedTypeEnum = {
  AbbsTransaction: 'abbs-transaction',
} as const;

export type DataCoreSvcFeedsCreateAddressedAbbsTransactionFeedTypeEnum =
  typeof DataCoreSvcFeedsCreateAddressedAbbsTransactionFeedTypeEnum[keyof typeof DataCoreSvcFeedsCreateAddressedAbbsTransactionFeedTypeEnum];

/**
 * @type DataCoreSvcFeedsCreateFeedUnion
 * @export
 */
export type DataCoreSvcFeedsCreateFeedUnion =
  | DataCoreSvcFeedsCreateAbbsTransactionFeed
  | DataCoreSvcFeedsCreateAddressedAbbsTransactionFeed
  | DataCoreSvcFeedsCreateTransactionFeed;

/**
 *
 * @export
 * @interface DataCoreSvcFeedsCreateTransactionFeed
 */
export interface DataCoreSvcFeedsCreateTransactionFeed {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateTransactionFeed
   */
  datasourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateTransactionFeed
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateTransactionFeed
   */
  type: DataCoreSvcFeedsCreateTransactionFeedTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsCreateTransactionFeed
   */
  walletId: string;
}

export const DataCoreSvcFeedsCreateTransactionFeedTypeEnum = {
  Transaction: 'transaction',
} as const;

export type DataCoreSvcFeedsCreateTransactionFeedTypeEnum =
  typeof DataCoreSvcFeedsCreateTransactionFeedTypeEnum[keyof typeof DataCoreSvcFeedsCreateTransactionFeedTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFeedsFeedPointer
 */
export interface DataCoreSvcFeedsFeedPointer {
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  createdAt?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  dataSourceId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  frequency?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  lastModifiedAt?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  lastModifiedBy?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedPointer
   */
  type?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedsFeedRunOutput
 */
export interface DataCoreSvcFeedsFeedRunOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedRunOutput
   */
  feedId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedRunOutput
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedRunOutput
   */
  status?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcHealthhdlGetOutput
 */
export interface DataCoreSvcHealthhdlGetOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcHealthhdlGetOutput
   */
  status: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcTransformsArrayColumnFilter
 */
export interface DataCoreSvcTransformsArrayColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsArrayColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsArrayColumnFilter
   */
  exact?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcTransformsArrayColumnFilter
   */
  reallyarray?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsArrayColumnFilter
   */
  type: DataCoreSvcTransformsArrayColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsArrayColumnFilterTypeEnum = {
  Array: 'array',
} as const;

export type DataCoreSvcTransformsArrayColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsArrayColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsArrayColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsBigNumberColumnFilter
 */
export interface DataCoreSvcTransformsBigNumberColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsBigNumberColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcTransformsBigNumberColumnFilter
   */
  equals?: number;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcTransformsBigNumberColumnFilter
   */
  greaterThan?: number;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcTransformsBigNumberColumnFilter
   */
  lessThan?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsBigNumberColumnFilter
   */
  type: DataCoreSvcTransformsBigNumberColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsBigNumberColumnFilterTypeEnum = {
  Bignumber: 'bignumber',
} as const;

export type DataCoreSvcTransformsBigNumberColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsBigNumberColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsBigNumberColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsBooleanColumnFilter
 */
export interface DataCoreSvcTransformsBooleanColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsBooleanColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcTransformsBooleanColumnFilter
   */
  exact?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsBooleanColumnFilter
   */
  type: DataCoreSvcTransformsBooleanColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsBooleanColumnFilterTypeEnum = {
  Boolean: 'boolean',
} as const;

export type DataCoreSvcTransformsBooleanColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsBooleanColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsBooleanColumnFilterTypeEnum];

/**
 * @type DataCoreSvcTransformsColumnFilterUnion
 * @export
 */
export type DataCoreSvcTransformsColumnFilterUnion =
  | DataCoreSvcTransformsArrayColumnFilter
  | DataCoreSvcTransformsBigNumberColumnFilter
  | DataCoreSvcTransformsBooleanColumnFilter
  | DataCoreSvcTransformsDateColumnFilter
  | DataCoreSvcTransformsEnumColumnFilter
  | DataCoreSvcTransformsNumberColumnFilter
  | DataCoreSvcTransformsObjectColumnFilter
  | DataCoreSvcTransformsStringColumnFilter
  | DataCoreSvcTransformsTickerColumnFilter
  | DataCoreSvcTransformsTimestampColumnFilter
  | DataCoreSvcTransformsUndefinedColumnFilter;

/**
 *
 * @export
 * @interface DataCoreSvcTransformsColumnOrderBy
 */
export interface DataCoreSvcTransformsColumnOrderBy {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsColumnOrderBy
   */
  columnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsColumnOrderBy
   */
  direction: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcTransformsDateColumnFilter
 */
export interface DataCoreSvcTransformsDateColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsDateColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsDateColumnFilter
   */
  maxExclusive?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsDateColumnFilter
   */
  minInclusive?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsDateColumnFilter
   */
  type: DataCoreSvcTransformsDateColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsDateColumnFilterTypeEnum = {
  Date: 'date',
} as const;

export type DataCoreSvcTransformsDateColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsDateColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsDateColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsEnumColumnFilter
 */
export interface DataCoreSvcTransformsEnumColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsEnumColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsEnumColumnFilter
   */
  exact?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsEnumColumnFilter
   */
  type: DataCoreSvcTransformsEnumColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsEnumColumnFilterTypeEnum = {
  Enum: 'enum',
} as const;

export type DataCoreSvcTransformsEnumColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsEnumColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsEnumColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsNumberColumnFilter
 */
export interface DataCoreSvcTransformsNumberColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsNumberColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcTransformsNumberColumnFilter
   */
  equals?: number;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcTransformsNumberColumnFilter
   */
  greaterThan?: number;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcTransformsNumberColumnFilter
   */
  lessThan?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsNumberColumnFilter
   */
  type: DataCoreSvcTransformsNumberColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsNumberColumnFilterTypeEnum = {
  Number: 'number',
} as const;

export type DataCoreSvcTransformsNumberColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsNumberColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsNumberColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsObjectColumnFilter
 */
export interface DataCoreSvcTransformsObjectColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsObjectColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsObjectColumnFilter
   */
  exact?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcTransformsObjectColumnFilter
   */
  reallyobject?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsObjectColumnFilter
   */
  type: DataCoreSvcTransformsObjectColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsObjectColumnFilterTypeEnum = {
  Object: 'object',
} as const;

export type DataCoreSvcTransformsObjectColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsObjectColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsObjectColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsStringColumnFilter
 */
export interface DataCoreSvcTransformsStringColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsStringColumnFilter
   */
  beginsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsStringColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsStringColumnFilter
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsStringColumnFilter
   */
  endsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsStringColumnFilter
   */
  exact?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsStringColumnFilter
   */
  type: DataCoreSvcTransformsStringColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsStringColumnFilterTypeEnum = {
  StringType: 'stringType',
} as const;

export type DataCoreSvcTransformsStringColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsStringColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsStringColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsTickerColumnFilter
 */
export interface DataCoreSvcTransformsTickerColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsTickerColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsTickerColumnFilter
   */
  exact?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsTickerColumnFilter
   */
  readllyticker?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsTickerColumnFilter
   */
  type: DataCoreSvcTransformsTickerColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsTickerColumnFilterTypeEnum = {
  Ticker: 'ticker',
} as const;

export type DataCoreSvcTransformsTickerColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsTickerColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsTickerColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsTimestampColumnFilter
 */
export interface DataCoreSvcTransformsTimestampColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsTimestampColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcTransformsTimestampColumnFilter
   */
  maxExclusive?: number;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcTransformsTimestampColumnFilter
   */
  minInclusive?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsTimestampColumnFilter
   */
  type: DataCoreSvcTransformsTimestampColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsTimestampColumnFilterTypeEnum = {
  Timestamp: 'timestamp',
} as const;

export type DataCoreSvcTransformsTimestampColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsTimestampColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsTimestampColumnFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcTransformsUndefinedColumnFilter
 */
export interface DataCoreSvcTransformsUndefinedColumnFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsUndefinedColumnFilter
   */
  columnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsUndefinedColumnFilter
   */
  exact?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcTransformsUndefinedColumnFilter
   */
  type: DataCoreSvcTransformsUndefinedColumnFilterTypeEnum;
}

export const DataCoreSvcTransformsUndefinedColumnFilterTypeEnum = {
  UndefinedType: 'undefinedType',
} as const;

export type DataCoreSvcTransformsUndefinedColumnFilterTypeEnum =
  typeof DataCoreSvcTransformsUndefinedColumnFilterTypeEnum[keyof typeof DataCoreSvcTransformsUndefinedColumnFilterTypeEnum];

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary data Source HTTP Handler Create
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerCreate: async (
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerCreate', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlCreateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerDelete: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerDelete', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerDelete', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Export
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerExport: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerExport', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerExport', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/export`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlRowsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGet: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGet', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGet', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerList: async (
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerList', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (matchesFeedSchemaType !== undefined) {
        localVarQueryParameter['matchesFeedSchemaType'] = matchesFeedSchemaType;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerRows: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerRows', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerRows', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/rows`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlRowsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerSchema: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerSchema', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerSchema', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/schema`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Validate
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerValidate: async (
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerValidate', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/validator`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlCreateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Create
     * @param {string} orgId
     * @param {DataCoreSvcFeedhdlCreateInput} [dataCoreSvcFeedhdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerCreate: async (
      orgId: string,
      dataCoreSvcFeedhdlCreateInput?: DataCoreSvcFeedhdlCreateInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerCreate', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/feeds`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcFeedhdlCreateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Get
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerGet: async (
      orgId: string,
      feedId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerGet', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerGet', 'feedId', feedId);
      const localVarPath = `/v3/orgs/{orgId}/feeds/{feedId}`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler List
     * @param {string} orgId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerList: async (
      orgId: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerList', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/feeds`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerRun: async (
      orgId: string,
      feedId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerRun', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerRun', 'feedId', feedId);
      const localVarPath = `/v3/orgs/{orgId}/feeds/{feedId}/run`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersHealthhdlHealthHTTPHandlerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersHealthhdlHealthHTTPHandlerGet2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/livez`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary data Source HTTP Handler Create
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerCreate(
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourcePointer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerCreate(
        orgId,
        dataCoreSvcDatasourcehdlCreateInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerDelete(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerDelete(
        orgId,
        dataSourceId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Export
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerExport(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlDownloadOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerExport(
        orgId,
        dataSourceId,
        pageToken,
        pageSize,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerGet(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourceUnion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerGet(
        orgId,
        dataSourceId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerList(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlListOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerList(
        orgId,
        matchesFeedSchemaType,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerRows(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlRowsOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerRows(
        orgId,
        dataSourceId,
        pageToken,
        pageSize,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerSchema(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlSchemaOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerSchema(
        orgId,
        dataSourceId,
        pageToken,
        pageSize,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Validate
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerValidate(
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourceValidationResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerValidate(
        orgId,
        dataCoreSvcDatasourcehdlCreateInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Create
     * @param {string} orgId
     * @param {DataCoreSvcFeedhdlCreateInput} [dataCoreSvcFeedhdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerCreate(
      orgId: string,
      dataCoreSvcFeedhdlCreateInput?: DataCoreSvcFeedhdlCreateInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedsFeedPointer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerCreate(
        orgId,
        dataCoreSvcFeedhdlCreateInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Get
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerGet(
      orgId: string,
      feedId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedhdlListOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerGet(
        orgId,
        feedId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler List
     * @param {string} orgId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerList(
      orgId: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedhdlListOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerList(
        orgId,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerRun(
      orgId: string,
      feedId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedsFeedRunOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerRun(
        orgId,
        feedId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersHealthhdlHealthHTTPHandlerGet(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcHealthhdlGetOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersHealthhdlHealthHTTPHandlerGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersHealthhdlHealthHTTPHandlerGet2(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcHealthhdlGetOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersHealthhdlHealthHTTPHandlerGet2(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary data Source HTTP Handler Create
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerCreate(
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourcePointer> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerCreate(orgId, dataCoreSvcDatasourcehdlCreateInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerDelete(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerDelete(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Export
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerExport(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlDownloadOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerExport(
          orgId,
          dataSourceId,
          pageToken,
          pageSize,
          dataCoreSvcDatasourcehdlRowsInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGet(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourceUnion> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerGet(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerList(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlListOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerList(orgId, matchesFeedSchemaType, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerRows(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlRowsOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerRows(
          orgId,
          dataSourceId,
          pageToken,
          pageSize,
          dataCoreSvcDatasourcehdlRowsInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerSchema(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlSchemaOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerSchema(orgId, dataSourceId, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Validate
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerValidate(
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourceValidationResult> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerValidate(orgId, dataCoreSvcDatasourcehdlCreateInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Create
     * @param {string} orgId
     * @param {DataCoreSvcFeedhdlCreateInput} [dataCoreSvcFeedhdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerCreate(
      orgId: string,
      dataCoreSvcFeedhdlCreateInput?: DataCoreSvcFeedhdlCreateInput,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedsFeedPointer> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerCreate(orgId, dataCoreSvcFeedhdlCreateInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Get
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerGet(
      orgId: string,
      feedId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedhdlListOutput> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerGet(orgId, feedId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler List
     * @param {string} orgId
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerList(
      orgId: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedhdlListOutput> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerList(orgId, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerRun(
      orgId: string,
      feedId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedsFeedRunOutput> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerRun(orgId, feedId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersHealthhdlHealthHTTPHandlerGet(options?: any): AxiosPromise<DataCoreSvcHealthhdlGetOutput> {
      return localVarFp.handlersHealthhdlHealthHTTPHandlerGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary health HTTP Handler Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersHealthhdlHealthHTTPHandlerGet2(options?: any): AxiosPromise<DataCoreSvcHealthhdlGetOutput> {
      return localVarFp.handlersHealthhdlHealthHTTPHandlerGet2(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary data Source HTTP Handler Create
   * @param {string} orgId
   * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerCreate(
    orgId: string,
    dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerCreate(orgId, dataCoreSvcDatasourcehdlCreateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Delete
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerDelete(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerDelete(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Export
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerExport(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    pageSize?: number,
    dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerExport(
        orgId,
        dataSourceId,
        pageToken,
        pageSize,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Get
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerGet(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerGet(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler List
   * @param {string} orgId
   * @param {string} [matchesFeedSchemaType]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerList(
    orgId: string,
    matchesFeedSchemaType?: string,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerList(orgId, matchesFeedSchemaType, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Rows
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerRows(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    pageSize?: number,
    dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerRows(
        orgId,
        dataSourceId,
        pageToken,
        pageSize,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Schema
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerSchema(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerSchema(orgId, dataSourceId, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Validate
   * @param {string} orgId
   * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerValidate(
    orgId: string,
    dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerValidate(orgId, dataCoreSvcDatasourcehdlCreateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Create
   * @param {string} orgId
   * @param {DataCoreSvcFeedhdlCreateInput} [dataCoreSvcFeedhdlCreateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerCreate(
    orgId: string,
    dataCoreSvcFeedhdlCreateInput?: DataCoreSvcFeedhdlCreateInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerCreate(orgId, dataCoreSvcFeedhdlCreateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Get
   * @param {string} orgId
   * @param {string} feedId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerGet(orgId: string, feedId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerGet(orgId, feedId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler List
   * @param {string} orgId
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerList(orgId: string, pageToken?: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerList(orgId, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Run
   * @param {string} orgId
   * @param {string} feedId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerRun(orgId: string, feedId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerRun(orgId, feedId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary health HTTP Handler Get
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersHealthhdlHealthHTTPHandlerGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersHealthhdlHealthHTTPHandlerGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary health HTTP Handler Get
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersHealthhdlHealthHTTPHandlerGet2(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersHealthhdlHealthHTTPHandlerGet2(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
